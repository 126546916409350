const dashboardTypes = {
    GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
    GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
    GET_DASHBOARD_DATA_FAILURE: 'GET_DASHBOARD_DATA_FAILURE',

    GET_PRODUCT_GRADE: 'GET_PRODUCT_GRADE',
    GET_PRODUCT_GRADE_SUCCESS: 'GET_PRODUCT_GRADE_SUCCESS',
    GET_PRODUCT_GRADE_FAILURE: 'GET_PRODUCT_GRADE_FAILURE',

    GET_NOTIFICATION: 'GET_NOTIFICATION',
    GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
    GET_NOTIFICATION_FAILURE: 'GET_NOTIFICATION_FAILURE',

    GET_CARDS_IN_ACCOUNT: 'GET_CARDS_IN_ACCOUNT',
    GET_CARDS_IN_ACCOUNT_SUCCESS: 'GET_CARDS_IN_ACCOUNT_SUCCESS',
    GET_CARDS_IN_ACCOUNT_FAILURE: 'GET_CARDS_IN_ACCOUNT_FAILURE'
}

export default dashboardTypes;
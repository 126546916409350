import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './authentication/auth.reducer';
import accountReducer from './account/account.reducer';
import userReducer from './user/user.reducer';
import cardTemplateReducer from './card-template/card-template.reducer';
import cardReducer from './card/card.reducer';
import CardOrderReducer from './card-order/card-order.reducer';
import cardOrderBatchReducer from './card-order-batch/card-order-batch.reducer';
import editProfileReducer from './edit-profile/edit-profile.reducer';
import deliveryReducer from './delivery-address/delivery-address.reducer';
import DashboardReducer from './dashboard/dashboard.reducer';
import ReportReducer from './report/report.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'account', 'editProfile']
};

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  user: userReducer,
  cardTemplate: cardTemplateReducer,
  card: cardReducer,
  cardOrder: CardOrderReducer,
  cardOrderBatch: cardOrderBatchReducer,
  editProfile: editProfileReducer,
  deliveryAddress: deliveryReducer,
  dashboard: DashboardReducer,
  report: ReportReducer
});

export default persistReducer(persistConfig, rootReducer);

const AuthActionTypes = {
    LOGIN: "LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    VERIFY_ACCOUNT: "VERIFY_ACCOUNT",
    VERIFY_ACCOUNT_SUCCESS: "VERIFY_ACCOUNT_SUCCESS",
    VERIFY_ACCOUNT_FAILURE: "VERIFY_ACCOUNT_FAILURE",
    LOGOUT: "LOGOUT",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",
    VERIFY_ACTIVATION: "VERIFY_ACTIVATION",
    VERIFY_ACTIVATION_SUCCESS: "VERIFY_ACTIVATION_SUCCESS",
    VERFIY_ACTIVATION_FAILURE: "VERIFY_ACTIVATION_FAILURE",
    ACTIVATION_ACCOUNT: "ACTIVATION_ACCOUNT",
    ACTIVATION_ACCOUNT_SUCCESS: "ACTIVATION_ACCOUNT_SUCCESS",
    ACTIVATION_ACCOUNT_FAILURE: "ACTIVATION_ACCOUNT_FAILURE",
    ACTIVATION_ACCOUNT_SET_INITIAL: "ACTIVATION_ACCOUNT_SET_INITIAL",
    CHECK_ACTIVATION_ACCOUNT: "CHECK_ACTIVATION_ACCOUNT",
    CHECK_ACTIVATION_ACCOUNT_SUCCESS: "CHECK_ACTIVATION_ACCOUNT_CHECK_SUCCESS",
    CHECK_ACTIVATION_ACCOUNT_ACTIVATED: "CHECK_ACTIVATION_ACCOUNT_ACTIVATED",
    CHECK_ACTIVATION_ACCOUNT_FAILURE: "CHECK_ACTIVATION_ACCOUNT_FAILURE",
    VERIFY_RESET_PASSWORD: "VERIFY_RESET_PASSWORD",
    VERIFY_RESET_PASSWORD_SUCCESS: "VERIFY_RESET_PASSWORD_SUCCESS",
    VERIFY_RESET_PASSWORD_FAILURE: "VERIFY_RESET_PASSWORD_FAILURE",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE"
  };
  
  export default AuthActionTypes;
  
import {  Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconContext } from "react-icons";
import { IoIosCard } from 'react-icons/io';
import { useSelector } from 'react-redux'
import './card-management.styles.scss';

const ThirdStep = () => {
    const { createCardData } = useSelector((state) => state.card)

    return (
        <Card.Body style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div className="card-review-container">
                <div className="card-review-icon">
                <IconContext.Provider
                    value={{ color: 'white', size: '70px' }}
                >
                <IoIosCard />
                </IconContext.Provider>
                </div>
                <h5 className="review-info">Order {createCardData[0].card_order_number} has been received.</h5>
                <Link to='/card-management' className="secondary-button d-flex align-items-center justify-content-center font-weight-normal mt-3">Go back to List of Cards</Link>
            </div>
        </Card.Body>
    )
};

export default ThirdStep;

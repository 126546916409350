import React, { useState, useEffect } from "react";
import { useLocation, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Container, Col, Row, Button, Spinner, Modal, Card } from "react-bootstrap";
import { Header, Menus, Footer } from "./../../components";
import Stepper from "react-stepper-horizontal";
import FirstStep from "./first-step.component";
import SecondStep from "./second-step.component";
import ThirdStep from "./third-step.component";
import { IconContext } from "react-icons";
import { RiErrorWarningLine } from 'react-icons/ri'
import {
  checkCreateCard,
  createCard,
  getCard,
  removeUploadedCardData
} from "../../redux/card/card.actions";
import { getData } from '../../redux/delivery-address/delivery-address.actions';
import { 
  getCardTemplates,
  loadStateCode,
  getCardPeriodGallon,
  getCardPeriodAmount,
  getVehicleTypes,
  getTimeProfiles,
  getFuelProfiles
} from './../../redux/card-template/card-template.actions'
import { createDataAddress } from "../../redux/delivery-address/delivery-address.actions";

import "./card-management.styles.scss";

const FormCardAdd = (props) => {
  function useQuery () {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const duplicateId = query.get("id")
  const duplicateAmount = query.get("amount")
  const dispatch = useDispatch();

  const stepper = [
    { title: "Cards" },
    { title: "Delivery Method" },
    { title: "Confirmation" },
  ]

  const {cardTemplates, statesOption} = useSelector((state) => state.cardTemplate)

  const [step, setStep] = useState(0);
  const [ loadingPage, setLoadingPage ] = useState(true)
  const [ listNewCard, setListNewCard ] = useState([])
  const [ deliveryAddress, setDeliveryAddress ] = useState(
    {
      create_new_address: true,
      delivery_address_name: "",
      delivery_attention_to: "",
      delivery_city: "",
      delivery_special_instruction: "",
      delivery_state: "",
      delivery_street_address: "",
      delivery_street_address_secondary: "",
      delivery_zipcode: "",
      pickup_address: "730 Central Ave S Kent, WA 98032",
      pickup_name: "",
      pickup_phone_number: "",
      delivery_phone_number: "",
      pickup_special_instruction: "",
      is_delivery: true,
      receive_pin_order: false,
      requestor: "",
      shipping_method: 'USPS'
    }
  )
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
  const [blockNavigate, updateBlockNavigate] = useState(true)
  const [ showVehicleTypePopup, setShowVehicleTypePopup ] = useState(false)
  const [ showTimeProfilePopup, setShowTimeProfilePopup ] = useState(false)
  const [ showFuelProfilePopup, setShowFuelProfilePopup ] = useState(false)
  const [ isVirtualCard, setIsVirtualCard ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState({})

  useEffect(() => {
    dispatch(getCardTemplates({ keyword: null, is_delete: 0, is_page: false, orderBy: 'template_name', sortedBy: 'asc'  }))
    .then(() => {
      dispatch(getCardPeriodGallon())
      dispatch(getCardPeriodAmount())
      dispatch(loadStateCode())
      dispatch(getVehicleTypes())
      dispatch(getTimeProfiles())
      dispatch(getFuelProfiles())
      .then(() => {
        setLoadingPage(false)
        dispatch(getData({ search: null, page: 1 }));
      })
    })
  }, [])

  useEffect(() => {
    loadCardDetail()
  }, [cardTemplates])

  const loadCardDetail = async () => {
    if (cardTemplates.data && cardTemplates.data.length > 0) {
      if (duplicateId !== null && duplicateAmount !== null) {

        let card = await dispatch(getCard(duplicateId))
        duplicateCard(card, duplicateAmount)

      } else if (duplicateId !== null && duplicateAmount === null) {

        let arr_duplicateId = duplicateId.split('|');
        arr_duplicateId.map( async function(id){
          let card = await dispatch(getCard(id))
          duplicateCard(card, null)
        })

      }
    }
  }

  const duplicateCard = (card, amount) => {
    let tempListNewCard = []
    let cardStates = card.states
    let cardZips = card.zips
    let tempCard = {...card}

    if(tempCard.card_type_name !== 'Single'){
      tempCard.pin = ''
    }
    tempCard.states = []
    tempCard.zips = []
    if (cardStates.length > 0) {
      cardStates.map((state) => {
        if (state !== '') {
          tempCard.states.push(
            {
              label: state,
              value: state
            }
          )
        }
      })
    }
    if (cardZips.length > 0) {
      cardZips.map((zip) => {
        if (zip !== '') {
          tempCard.zips.push(zip)
        }
      })
    }
    if(amount === null){
      setListNewCard(prevArray => [...prevArray, tempCard])
    }else{
      for (let i = 0; i < amount; i++) {
        tempListNewCard.push({...tempCard})
      }
      setListNewCard([...tempListNewCard])
    }
  }

  const attachCardValue = async (cards, isVirtual) => {
    setIsVirtualCard(isVirtual)
    setListNewCard([...cards])
    // handleNext()

    let payloadCard = {
      data: cards
    }
    // console.log('payloadCheckCard :>> ', payloadCard);
    let response = await dispatch(checkCreateCard(payloadCard))
    if (response.status === 'success') {
      handleNext()
    } else {
      if (response.status === 422) {
        let errors = response.data.errors
        let isCardError = false
        let listField = ['vehicle_number', 'card_fuel_profile_id', 'card_time_profile_id', 'max_gallon_fill', 'max_gallon_period', 'card_period_gallon_id', 'pin']
        setErrorMessage({...errors})
        for (var key in errors) {
          listField.map((field) => {
            if (key.includes(field)) {
              isCardError = true
            }
          })
        }
        if (isCardError) {
          setStep(0)
        }
      }
    }
  }

  const attachAddressValue = async (address) => {
    setDeliveryAddress({...address})
    let tempListNewCard = JSON.parse(JSON.stringify(listNewCard))
    tempListNewCard.map((card, index) => {
      let tempCard = { ...card, ...address}
      tempListNewCard[index] = {...tempCard}
    })

    listNewCard.map((card, index) => {
      tempListNewCard[index].states = []
      if (card.states.length > 0) {
        if (card.states[0].hasOwnProperty('value')) {
          if(card.states[0].value){
            card.states.map((state) => {
              tempListNewCard[index].states.push(state.value)
            })
          }else{
            card.states.map((state) => {
              let indexState = statesOption.findIndex(x => x.name === state.label)
              if (indexState !== -1) {
                tempListNewCard[index].states.push(statesOption[indexState].id)
              }
            })
          }
        }
      }
    })
    setListNewCard([...tempListNewCard])
    let payloadCard = {
      data: []
    }
    payloadCard.data = [...tempListNewCard]
    for (let i = 0; i < payloadCard.data.length; i++) {
      delete payloadCard.data[i].create_new_address
    }
    // console.log('payloadCard :>> ', payloadCard);
    let response = await dispatch(createCard(payloadCard))
    if (response.status === 'success') {
      updateBlockNavigate(false)
      if (address.create_new_address) {
        if (!isVirtualCard) {
          createNewAddress(address)
        }
      }
      handleNext()
    } else {
      if (response.status === 422) {
        let errors = response.data.errors
        let isCardError = false
        let listField = ['card_fuel_profile_id', 'card_time_profile_id', 'max_gallon_fill', 'max_gallon_period', 'card_period_gallon_id', 'pin']
        setErrorMessage({...errors})
        for (var key in errors) {
          listField.map((field) => {
            if (key.includes(field)) {
              isCardError = true
            }
          })
        }
        if (isCardError) {
          setStep(0)
        }
      }
    }
  }

  const createNewAddress = (address) => {
    let payloadAddress = {
      account_id: '',
      address_name: address.delivery_address_name,
      attention_to: address.delivery_attention_to,
      email: '',
      phone: address.delivery_phone_number,
      fax: '',
      address: address.delivery_street_address,
      address_secondary: address.delivery_street_address_secondary,
      city: address.delivery_city,
      state: address.delivery_state,
      zipcode: address.delivery_zipcode
    }
    dispatch(createDataAddress(payloadAddress))
  }

  const handleNext = () => {
    setStep(step + 1);
  }

  const backStep = () => {
    setStep(step - 1)
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    if (lastLocation) {
      updateBlockNavigate(false)
      updateConfirmedNavigation(true);
    }
    dispatch(removeUploadedCardData())
    closeModal()
  };

  useEffect(() => {
    if (confirmedNavigation) {
      props.history.push(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  const showModal = (location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = () => {
    updateModalVisible(false);
  };

  const showPopup = (type) => {
    if (type === 'vehicle') {
      setShowVehicleTypePopup(true)  
    } else if (type === 'timeprofile') {
      setShowTimeProfilePopup(true)  
    } else {
      setShowFuelProfilePopup(true)  
    }
  }

  const closePopup = () => {
    setShowVehicleTypePopup(false)
    setShowTimeProfilePopup(false)
    setShowFuelProfilePopup(false)
  }

  const renderStepperComponent = () => {
    switch (step) {
      case 0:
        return <FirstStep
                  listNewCard={listNewCard}
                  step={step}
                  duplicateAmount={duplicateAmount}
                  handleNext={(cards, isVirtual) => {attachCardValue(cards, isVirtual)}}
                  showVehicleTypePopup={showVehicleTypePopup}
                  showFuelProfilePopup={showFuelProfilePopup}
                  showTimeProfilePopup={showTimeProfilePopup}
                  showPopup={(type) => showPopup(type)}
                  errorMessage={errorMessage}
        />
      case 1:
        return <SecondStep
                handleNext={(address) => attachAddressValue(address)}
                backStep={
                  (address) => { 
                    setDeliveryAddress({...address});
                    backStep()
                  }
                }
                deliveryAddress={deliveryAddress}
                isVirtualCard={isVirtualCard}
        />
      case 2:
        return <ThirdStep />
    }
  }

  return (
    <Container fluid className="card-management-container" onClick={() => closePopup()}>
      <Prompt when={blockNavigate} message={handleBlockedNavigation} />
      <div className="header-container">
        <Header {...props} />
      </div>
      <Container style={{maxWidth: '1366px'}}>
        <Menus {...props} />
        <Row className="page-container">
          <Col md={12} lg={12}>
            <div className="page-title-wrapper">
              <div className="title-container">
                <span className="page-title">NEW CARD ORDER</span>
                <span className="page-sub-title">
                  Dashboard / Card Maintenance / New Card Order
                </span>
              </div>
            </div>
          </Col>
        </Row>
        {
          loadingPage
          ? <div className="handle-loading">
          <Spinner animation="border" variant="dark" />
        </div> 
        : <Row>
            <Col md={12} lg={12}>
              <div className="user-body-container">
                <div className="stepper">
                  <div className="stepper-inner">
                    <Stepper
                      steps={stepper}
                      activeStep={step}
                      activeColor="#ef5350"
                      completeBarColor="#ef5350"
                      completeColor="#ef5350"
                      defaultColor="#ef5350"
                      defaultBarColor="#ef5350"
                      defaultOpacity="0.5"
                      completeOpacity="1"
                      defaultBorderWidth={50}
                    />
                  </div>
                </div>
                {renderStepperComponent()}
              </div>
            </Col>
          </Row>
        }
        <Modal show={modalVisible} onHide={closeModal} className="modal-container-card" centered>
        <Modal.Header closeButton className="header-form">
            <Modal.Title>Add New Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <Card.Body>
               <div className="d-flex align-items-center flex-column">
                    <IconContext.Provider
                        value={{ color: '#ef5350', size: '70px' }}
                    >
                        <div>
                            <RiErrorWarningLine />
                        </div>
                    </IconContext.Provider>
                    <h5 className="text-danger mt-3">Your card order hasn't been submitted yet.</h5>
                    <p className="text-center">Are you sure you want to exit this process?</p>
                    <div className="button-container">
                        <button className="secondary-button mr-3" onClick={() => handleConfirmNavigationClick()}>Yes, exit</button>
                        <button className="primary-button" onClick={() => closeModal()}>NO, CONTINUE</button>
                    </div>
                </div>
           </Card.Body>
        </Modal.Body>

    </Modal>
      </Container>
      <Footer />
    </Container>
  )
}

export default FormCardAdd;
const DeliveryActionTypes = {
    CREATE_DELIVERY: "CREATE_DELIVERY",
    CREATE_DELIVERY_SUCCESS: "CREATE_DELIVERY_SUCCESS",
    CREATE_DELIVERY_FAILURE: "CREATE_DELIVERY_FAILURE",
    GET_DATA: "GET_DATA",
    GET_DATA_SUCCESS: "GET_DATA_SUCCESS",
    GET_DATA_FAILURE: "GET_DATA_FAILURE",
    DELETE_DELIVERY: "DELETE_DELIVERY",
    DELETE_DELIVERY_SUCCESS: "DELETE_DELIVERY_SUCCESS",
    DELETE_DELIVERY_FAILURE: "DELETE_DELIVERY_FAILURE",
    GET_STATE_OPTION: "GET_STATE_OPTION",
    GET_STATE_OPTION_SUCCESS: "GET_STATE_OPTION_SUCCESS",
    GET_STATE_OPTION_FAILURE: "GET_STATE_OPTION_FAILURE",
    UPDATE_DELIVERY: "UPDATE_DELIVERY",
    UPDATE_DELIVERY_SUCCESS: "UPDATE_DELIVERY_SUCCESS",
    UPDATE_DELIVERY_FAILURE: "UPDATE_DELIVERY_FAILURE",
  };
  
  export default DeliveryActionTypes;
  
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { IconContext } from "react-icons";
import { BsGridFill, BsExclamationCircleFill, BsFillPersonFill, BsFillPersonCheckFill,BsBell } from 'react-icons/bs'
import { FaAddressCard } from 'react-icons/fa'
import { verifyAccount } from './../../redux/authentication/auth.actions';
import moment from 'moment';
import { IoIosPeople } from 'react-icons/io'
import Select from "react-select";
import AsyncSelect from 'react-select/async'
// import Components 
import { Navbar, Nav, Row, Col, Container, Button, NavDropdown, Form, InputGroup, FormControl, DropdownButton, Dropdown, Popover, OverlayTrigger } from "react-bootstrap";


// import actions 
import { logout, tokenExpired } from './../../redux/authentication/auth.actions';
import { getAccount } from './../../redux/account/account.actions';

// import all image
import Logo from '../../assets/logo.jpg';

// import all styles
import "./header.styles.scss";

const Header = ({ history }) => {
    
    const dispatch = useDispatch();
    const { account } = useSelector((state) => state.account)
    const { token, user } = useSelector((state) => state.auth)
    const { role } = useSelector(state => state.auth);
    const selectAccount = useSelector(state => state.auth.account);
    const [showAccount, setShowAccount] = useState(false);
    const selectedAccount = {
        label: selectAccount !== null ? (selectAccount.account.name + ' - ' + selectAccount.account.cl_division_no + '-' + selectAccount.account.cl_account_no) : '',
        value: selectAccount !== null ? selectAccount.account.id : ''
    }

    const menuTitleCard = <div className="menu"><IconContext.Provider value={{ size: '15px' }}><span className="header-icon"><FaAddressCard /></span></IconContext.Provider>Card Maintenance</div>;
    const menuTitleReport = <div className="menu"><IconContext.Provider value={{ size: '15px' }}><span className="header-icon"><BsExclamationCircleFill /></span></IconContext.Provider>Report</div>;
    const menuTitleAccount = <div className="menu"><IconContext.Provider value={{ size: '15px' }}><span className="header-icon"><BsFillPersonCheckFill /></span></IconContext.Provider>Account Maintenance</div>;

    const logouts = () => {
        dispatch(logout());
    }


    const setDefaultListPetrocardAccounts = () => {
        let defaultList = []
        if (account !== null && account.hasOwnProperty('accounts')) {
            account.accounts.map((account) => {
                defaultList.push(
                    {
                        label: account.account_name + ' - ' + account.division_number + '-' + account.account_number.substring(2),
                        value: account.account_id
                    }
                )
            })
        }
        return defaultList
    }

    const loadPetrocardAccounts = async (inputText, callback) => {
        if (inputText.length > 3) {
            inputText = inputText.replace('0-','0')
            inputText = inputText.replace('-0','0')
            inputText = encodeURIComponent(inputText)
            const response = await dispatch(getAccount(inputText))
            if (response.status === 200) {
                let listAccount = response.data.data.accounts
                callback(listAccount.map(account => ({
                    label: account.account_name + ' - ' + account.division_number + '-' + account.account_number.substring(2),
                    value: account.account_id
                })))
            }
        }
    }

    const getDropdownValue = (arrayData, key) => {
        let val = ''
        if (selectAccount !== null) {
            let index = arrayData.findIndex(x => x.account_id === selectAccount.account.id)
            if (index !== -1) {
                val = {
                    label: arrayData.length > 0 && selectAccount.account.id !== '' ? arrayData[index].account_name + ' - ' + arrayData[index].account_number : '',
                    value: selectAccount.account.account_number
                }
            }
        }
        return val
      }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <span
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="header-logout"
        >
            {children}
            <span className="threedots">
                &#8942;
            </span>
        </span>
    ));

    // console.log('user :>> ', user);
    // console.log('selectAccount :>> ', selectAccount);

    const auth = () => {
        return <Row className="header-auth">
            <Col lg={12} md={12}>
                <Navbar expand="lg">
                    <Navbar.Brand href="/dashboard"><img src={Logo} alt='logo-auth' className="logo-auth" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto align-items-center">
                            <Nav.Link href="https://booknow.appointment-plus.com/y7pt74rc/">
                                <Button className="header-signup-button">
                                    <IconContext.Provider
                                            value={{ color: '#ffffff', size: '20px' }}
                                        >
                                        <IoIosPeople style={{ marginRight: '10px' }} />
                                    </IconContext.Provider>
                                    <span>Book a Training</span>
                                </Button>
                            </Nav.Link>
                            {
                                token !== '' ?
                                <div 
                                    className="ml-2" 
                                    style={{ color: '#ff0100', textDecoration: 'underline', cursor: 'pointer' }} 
                                    onClick={() => logouts()}
                                >
                                    Log Out
                                </div>
                                : ''
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Col>
        </Row>
    }

    const header = () => {


        return <div>
            <Container style={{maxWidth: '1366px'}}>
                <Row className="header-auth">
                    <Col lg={12} md={12}>
                        <Navbar expand="lg">
                            <Navbar.Brand href="/dashboard"><img src={Logo} alt='logo-auth' className="logo-auth" /></Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="ml-auto">
                                    <div className="setting-container">
                                        {/* <Form.Control
                                            as="select"
                                            placeholder="Choose Account"
                                            aria-label="Choose Account"
                                            aria-describedby="basic-addon2"
                                            value={selectAccount.account.account_number}
                                            onChange={async (event) => {
                                                await dispatch(verifyAccount(event.target.value));
                                                window.location.reload();
                                            }
                                            }
                                        >
                                            {
                                                account && account.accounts.map((item) => {
                                                    return <option style={{ height: '30px' }} key={item.account_id} value={item.account_number}>
                                                    {item.account_name} - {item.account_number}
                                                    </option>
                                                })
                                            }
                                        </Form.Control> */}
                                        {
                                            user !== null && user.user_type_id === 1 ?
                                            <AsyncSelect 
                                                loadOptions={loadPetrocardAccounts}
                                                defaultOptions={setDefaultListPetrocardAccounts()}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                styles={
                                                    {
                                                        option: (styles, state) => ({
                                                            ...styles,
                                                            background: state.isSelected ? '#ff0100' : 'white',
                                                            color: state.isSelected ? 'white' : '#495057',
                                                            cursor: 'pointer'
                                                        }),
                                                        control: styles => ({ ...styles, cursor: 'pointer' }),
                                                        container: styles => ({ ...styles, width: '100%' }),
                                                        singleValue: styles => ({ ...styles }),
                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                    }
                                                }
                                                value={selectedAccount}
                                                onChange={async (account) => {
                                                    await dispatch(verifyAccount(account.value));
                                                    window.location.reload();
                                                }}
                                            />
                                            :
                                            <Select 
                                                placeholder=""
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                value={selectedAccount}
                                                styles={
                                                    {
                                                        option: (styles, state) => ({
                                                            ...styles,
                                                            background: state.isSelected ? '#ff0100' : 'white',
                                                            color: state.isSelected ? 'white' : '#495057',
                                                            cursor: 'pointer'
                                                        }),
                                                        control: styles => ({ ...styles, cursor: 'pointer' }),
                                                        container: styles => ({ ...styles, width: '100%' }),
                                                        singleValue: styles => ({ ...styles }),
                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                    }
                                                }
                                                menuPortalTarget={document.querySelector('body')}
                                                menuShouldBlockScroll={true}
                                                options={account.accounts.map((account) => {
                                                    return {
                                                            label: account.account_name + ' - ' + account.division_number + '-' + account.account_number.substring(2),
                                                            value: account.account_id
                                                        }
                                                    })
                                                }
                                                onChange={async (event) => {
                                                    await dispatch(verifyAccount(event.value));
                                                    window.location.reload();
                                                    }
                                                }
                                            />
                                        }
                                    </div>
                                    <div className="header-profile" onClick={() => { setShowAccount(!showAccount) }}>
                                        <span className="user">{selectAccount ? selectAccount.account.name : ''}</span>
                                        <span>{selectAccount ? selectAccount.account.cl_division_no + '-' + selectAccount.account.cl_account_no : '' }</span>
                                        <span>{user.name}</span>
                                        {/* <span className="account"> Last Login: {moment.utc(selectAccount.account.updated_at).format("MM/DD/YYYY HH:mm")}</span> */}
                                    </div>
                                </Nav>
                                <div className="mobile-menu">
                                    <Nav.Item className="header-icon-container">
                                        <Nav.Link className="menu" eventKey="link-1" onClick={() => window.location = '/dashboard'} >
                                            <IconContext.Provider
                                                value={{ size: '15px' }}
                                            >
                                                <span className="header-icon">
                                                    <BsGridFill />
                                                </span>
                                            </IconContext.Provider>
                                            <span>
                                                Dashboard
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="header-icon-container">
                                        <NavDropdown title={menuTitleCard} id="nav-dropdown">
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/card-management'}>Edit Cards</NavDropdown.Item>
                                            {
                                                user.user_type_id === 1 && <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/card-template'}>Card Templates</NavDropdown.Item>
                                            }
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/card-order'}>Card Order</NavDropdown.Item>
                                            {
                                                user.user_type_id === 1 && <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/card-order-batch'}>Batches</NavDropdown.Item>
                                            }
                                        </NavDropdown>
                                    </Nav.Item>
                                    <Nav.Item className="header-icon-container">
                                        <NavDropdown title={menuTitleReport} id="nav-dropdown">
                                            <NavDropdown.Header>On Demand Report</NavDropdown.Header>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/transaction-detail-report'}>Transaction Detail Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/fuel-summary-report'}>Fuel Summary Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/billing-sort-report'}>Billing Sort Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/list-driver-or-vehicle-report'}>List Driver or Vehicle Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/exception-report'}>Exception Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/tax-summary-report'}>Tax Summary Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/owner-operator-report'}>Owner Operator Report</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Header>Admin Report</NavDropdown.Header>
                                            <NavDropdown.Item  eventKey="4.1" onClick={() => window.location = '/tax-exempt-report'}>Tax Exempt Report</NavDropdown.Item>
                                            <NavDropdown.Item  eventKey="4.1" onClick={() => window.location = '/submit-log-report'}>Submit Log Report</NavDropdown.Item>
                                            <NavDropdown.Item  eventKey="4.1" onClick={() => window.location = '/user-log-report'}>User Log Report</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Header>Scheduled Report</NavDropdown.Header>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/daily-transaction-report'}>Daily Transaction Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/scheduled-exception-report'}>Exception Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/invoiced-transaction-report'}>Invoiced Transaction Report</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/driver-vehicle-records-monthly-audit-report'}>Driver and Vehicle Records Monthly Audit Report</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav.Item>
                                    {/* <Nav.Item className="header-icon-container">
                                        <Nav.Link className="menu" active={pathname === '/user' ? true : false} eventKey="link-1" onClick={() => window.location = '/user')}>
                                            <IconContext.Provider
                                                value={{ size: '15px' }}
                                            >
                                                <span className="header-icon">
                                                    <BsFillPersonFill />
                                                </span>
                                            </IconContext.Provider>
                                            <span>User Maintenance</span>
                                        </Nav.Link>
                                    </Nav.Item> */}
                                    {/* <Nav.Item className="header-icon-container">
                                        <IconContext.Provider
                                            value={{ size: '15px' }}
                                        >
                                            <span className="header-icon">
                                                <BsFillPersonCheckFill />
                                            </span>
                                        </IconContext.Provider>
                                        <Nav.Link eventKey="link-1" onClick={() => window.location = '/account')} >Account Maintenance</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item className="header-icon-container">
                                        <NavDropdown title={menuTitleAccount} id="nav-dropdown">
                                            <NavDropdown.Item onClick={() => window.location = '/billing-information'}> Billing Information</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/delivery-address'}>Delivery Addresses</NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.1" onClick={() => window.location = '/user'}>User Maintenance</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav.Item>
                                    <Nav.Item className="header-icon-container pl-4" href="mailto:service@petrocard.com ">Contact Support</Nav.Item>
                                    <Nav.Item className="header-icon-container mt-2 pl-4" onClick={() =>
                                        window.location = '/edit-password'
                                    }>Edit Password</Nav.Item>
                                    <Nav.Item className="header-icon-container mt-2 pl-4" onClick={() => {
                                        logouts()
                                    }}>Logout</Nav.Item>
                                </div>
                                <OverlayTrigger
                                    key="left"
                                    placement="left"
                                    overlay={
                                        <Popover>
                                            <Popover.Content>
                                                <span>Options</span>      
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <div className="dropdown-logout">
                                        <Dropdown >
                                            <Dropdown.Toggle as={CustomToggle}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="md" title="">
                                                <Dropdown.Item href="mailto:service@petrocard.com ">Contact Support</Dropdown.Item>
                                                <Dropdown.Item onClick={() =>
                                                    window.location = '/edit-password'
                                                }>Edit Password</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    logouts()
                                                }}>Logout</Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </OverlayTrigger>
                            </Navbar.Collapse>
                        </Navbar>

                    </Col>
                </Row>
            </Container>
        </div >
    }

    const render = () => {
        if (role === "auth") {
            return auth();
        }
        return header();
    }

    return render();
}

export default Header;

const reportTypes = {
    GET_INVOICES: 'GET_INVOICES',
    GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
    GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',

    GET_CARD_RANGES: 'GET_CARD_RANGES',
    GET_CARD_RANGES_SUCCESS: 'GET_CARD_RANGES_SUCCESS',
    GET_CARD_RANGES_FAILURE: 'GET_CARD_RANGES_FAILURE',

    GET_VEHICLE_RANGES: 'GET_VEHICLE_RANGES',
    GET_VEHICLE_RANGES_SUCCESS: 'GET_VEHICLE_RANGES_SUCCESS',
    GET_VEHICLE_RANGES_FAILURE: 'GET_VEHICLE_RANGES_FAILURE',

    CREATE_REPORT: 'CREATE_REPORT',
    CREATE_REPORT_SUCCESS: 'CREATE_REPORT_SUCCESS',
    CREATE_REPORT_FAILURE: 'CREATE_REPORT_FAILURE',

    GET_REPORT: 'GET_REPORT',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',

    GET_EXEMPT_CARDS: 'GET_EXEMPT_CARDS',
    GET_EXEMPT_CARDS_SUCCESS: 'GET_EXEMPT_CARDS_SUCCESS',
    GET_EXEMPT__CARDS_FAILURE: 'GET_EXEMPT_CARDS_FAILURE'
}

export default reportTypes;
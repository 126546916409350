import axios from "../../axiosInstance";
import DeliveryActionTypes from "./delivery-address.types";
import BillingActionTypes from "./billing-information.types";
import UserActionTypes from "../user/user.types";
import { batch } from "react-redux";
import { tokenExpired } from '../authentication/auth.actions'

const userUrl = `/v1/`;
const allAddressUrl = `/v1/get_all_account_address`;
const createAddressUrl = `/v1/create_account_address`;
const updateAddressUrl = `/v1/update_account_address`;
const deleteDeliveryAddressUrl = `/v1/delete_account_address/`;

const billingInformationUrl = "/v1/find_account_billing";
const updateBillingInformationUrl = "/v1/update_account_billing/";
const updateBillingInformationUrlNew = "/v1/account/";
const createBillingInformationUrl = "/v1/create_account_billing";

const statesOptionUrl = "/v1/state_countries";

export const getBillingInformation = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const account = state.auth.account;

    try {
      dispatch({
        type: BillingActionTypes.GET_BILLING_INFORMATION,
      });

      const res = await axios.get(`${billingInformationUrl}`, {
        headers: {
          Authorization: "Bearer " + token,
          AccountId: account.account.id,
        },
      });

      dispatch({
        type: BillingActionTypes.GET_BILLING_INFORMATION_SUCCESS,
        payload: res.data,
      });

      return res;
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: BillingActionTypes.GET_BILLING_INFORMATION_FAILURE,
      });
      return err.response
    }
  };
};

export const updateBillingInformation = (data, id, type) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const account = state.auth.account;
    const account_id = account.account.id;
    const postfix = '/update-billing';

    try {
      dispatch({
        type: BillingActionTypes.UPDATE_BILLING_INFORMATION,
      });

      data.account_id = account.account.id;

      let res = null;

      if (type === "create") {
        res = await axios.post(`${createBillingInformationUrl}`, data, {
          headers: {
            Authorization: "Bearer " + token,
            AccountId: account.account.id,
          },
        });
      } else {
        res = await axios.post(`${updateBillingInformationUrlNew}${account_id}${postfix}`, data, {
          headers: {
            Authorization: "Bearer " + token,
            AccountId: account.account.id,
          },
        });
      }

      batch(() => {
        dispatch({
          type: BillingActionTypes.UPDATE_BILLING_INFORMATION_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
          payload: {
            status: BillingActionTypes.UPDATE_BILLING_INFORMATION_SUCCESS,
            error: false
          }
        });
      })
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      batch(() => {
        dispatch({
          type: BillingActionTypes.UPDATE_BILLING_INFORMATION_FAILURE,
        });
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
          payload: {
            status: BillingActionTypes.UPDATE_BILLING_INFORMATION_FAILURE,
            error: true
          }
        });
      })
    }
  };
};

export const getData = ({ search = null, page = 1, orderBy = null, sortedBy = null }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const account = state.auth.account;

    try {
      dispatch({
        type: DeliveryActionTypes.GET_DATA,
      });

      const res = await axios.get(allAddressUrl, {
        params: { page, orderBy, sortedBy },
        headers: {
          Authorization: "Bearer " + token,
          AccountId: account.account.id,
        },
      });

      dispatch({
        type: DeliveryActionTypes.GET_DATA_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: DeliveryActionTypes.GET_DATA_FAILURE,
      });
    }
  };
};

export const createDataAddress = (form) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const account = state.auth.account;

    try {
      dispatch({
        type: DeliveryActionTypes.CREATE_DELIVERY,
      });

      const res = await axios.post(
        createAddressUrl,
        { ...form, account_id: account.account.id, state: form.state },
        {
          headers: {
            Authorization: "Bearer " + token,
            AccountId: account.account.id,
          },
        }
      );
      batch(() => {
        dispatch({
          type: DeliveryActionTypes.CREATE_DELIVERY_SUCCESS,
          payload: form,
        });
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
          payload: {
            email: "",
            status: DeliveryActionTypes.CREATE_DELIVERY_SUCCESS,
            error: false,
            withActionButton: false,
          }
        });
      })

      return res

    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      batch(() => {
        dispatch({
          type: DeliveryActionTypes.CREATE_DELIVERY_FAILURE,
        });
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
          payload: {
            email: "",
            status: DeliveryActionTypes.CREATE_DELIVERY_FAILURE,
            error: true,
            withActionButton: false,
          }
        });
      })

      return err.response
    }
  };
};

export const getStatesOption = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    dispatch({ type: DeliveryActionTypes.GET_STATE_OPTION });
    const headers = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    try {
      const stateOptions = await axios.get(statesOptionUrl, headers);
      dispatch({
        type: DeliveryActionTypes.GET_STATE_OPTION_SUCCESS,
        payload: stateOptions.data.data,
      });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({ type: DeliveryActionTypes.GET_STATE_OPTION_FAILURE });
    }
  };
};

export const updateAddress = (rawData, docId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const account = state.auth.account;

    const dataToSend = {
      account_id: account.account.id,
      address_name: rawData.address_name,
      attention_to: rawData.attention_to,
      email: rawData.email,
      phone: rawData.phone,
      fax: rawData.fax,
      address: rawData.address,
      city: rawData.city,
      state: rawData.state,
      zipcode: rawData.zipcode,
    };

    try {
      dispatch({ type: DeliveryActionTypes.UPDATE_DELIVERY });

      const res = await axios.put(
        `${updateAddressUrl}/${docId}`,
        dataToSend,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      batch(() => {
        dispatch({
          type: DeliveryActionTypes.UPDATE_DELIVERY_SUCCESS,
        });
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
          payload: {
            email: "",
            status: DeliveryActionTypes.UPDATE_DELIVERY_SUCCESS,
            error: false,
            withActionButton: false,
          }
        });
      })
      
      return res
      
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(tokenExpired())
      }
      batch(() => {
        dispatch({
          type: DeliveryActionTypes.UPDATE_DELIVERY_FAILURE,
        });
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
          payload: {
            email: "",
            status: DeliveryActionTypes.UPDATE_DELIVERY_FAILURE,
            error: true,
            withActionButton: false,
          }
        });
      })
      return error.response
    }
  };
};


export const deleteDeliveryAddress = (deliveryAddressId) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token

      try {
        dispatch({
          type: DeliveryActionTypes.DELETE_DELIVERY
        })

      
        const res = await axios.delete(
          `${deleteDeliveryAddressUrl}${deliveryAddressId}`,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )

        batch(() => {
          dispatch({
            type: DeliveryActionTypes.DELETE_DELIVERY_SUCCESS,
            payload: res.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: '',
              status: DeliveryActionTypes.DELETE_DELIVERY_SUCCESS,
              error: false
            }
          })
        })

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: DeliveryActionTypes.DELETE_DELIVERY_FAILURE
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
              payload: {
                email: '',
                status: DeliveryActionTypes.DELETE_DELIVERY_FAILURE,
                error: true
              }
          })
        })
      }
    }
  }
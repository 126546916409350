import React, { useState } from 'react';
// import Components 
import { ReactComponent as KeyIcon } from './../../assets/key.svg';
import { ReactComponent as UserIcon } from './../../assets/user.svg';
import { Modal, Button } from "react-bootstrap";
import "./modal-message.styles.scss";


const ModalMessage = ({ type, title, email }) => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);

    const renderBody = () => {
        switch (type) {
            case 'error-activation':
                return <div className="reset-message">
                    <div className="eclipse">
                        <KeyIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <span>
                        {email}
                    </span>
                </div>
            case 'reset-password':
                return <div className="reset-message">
                    <div className="eclipse">
                        <KeyIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <span>
                        Your password has been successfully reset!
                    </span>
                    <span className="click-message">
                        Click <a href="/auth/signin">here</a> to login to your account.
                    </span>
                </div>
            case 'account-activation':
                return <div className="reset-message">
                    <div className="eclipse">
                        <UserIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <span>
                        Your account has been successfully activated!
                    </span>
                    <span className="click-message">
                        Click <a href="/auth/signin">here</a> to login to your account.
                    </span>
                </div>
            case 'edit-profile':
                return <div className="reset-message">
                    <div className="eclipse">
                        <UserIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <span>
                        Your Profile has been successfully updated!
                    </span>
                </div>
            case 'edit-password':
                return <div className="reset-message">
                    <div className="eclipse">
                        <KeyIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <span>
                        Your password has been successfully updated!
                    </span>
                </div>
            case 'add-user':
                return <div className="reset-message">
                    <div className="eclipse">
                        <UserIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <span>
                        Success
                    </span>
                    <div className="message">
                        We’ve sent an invitation to {email}
                    </div>
                </div>

            default:
                return <span></span>
        }
    }

    return (
        <Modal show={show} onHide={handleClose}
            className="modal-message-container"
            size="lg"
            centered>
            <Modal.Header className="header-form">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                {renderBody()}
            </Modal.Body>
        </Modal>
    )
}

export default ModalMessage;

const AccountActionTypes = {
    GET_ACCOUNT: "GET_ACCOUNT",
    GET_ACCOUNT_SUCCESS: "GET_ACCOUNT_SUCCESS",
    GET_ACCOUNT_FAILURE: "GET_ACCOUNT_FAILURE",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
    DEFAULT_ACCOUNT: "DEFAULT_ACCOUNT"
  };
  
  export default AccountActionTypes;
  
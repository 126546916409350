import CardOrderBatchActionTypes from './card-order-batch.types'

const INITIAL_STATE = {
  cardOrderBatches: {
    data: []
  },
  cardOrdersToBatch: {
    data: []
  },
  detailCardOrderBatch: null,
  createdCardOrderBatch: {},
  detailAssignedCardOrderBatch: {},
  loadingCardOrderBatches: false,
  loadingCardOrderToBatch: false,
  loadingCreateCardOrderBatch: false,
  loadingGenerateCard: false,
  cardOrderBatchStep: 0,
  cardOrderBatchErrorMsg: '',
  removeCardOrder: true,
  removeCardOrderErrorMsg: '',
  loadingRemoveCardOrder: false,
  detailPinAvailable: {},
  loadingPinAvailable: false
}

const CardOrderBatchReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CardOrderBatchActionTypes.GET_CARD_ORDER_BATCHES:
      return {
        ...state,
        loadingCardOrderBatches: true
      }
    case CardOrderBatchActionTypes.GET_CARD_ORDER_BATCHES_SUCCESS:
      return {
        ...state,
        loadingCardOrderBatches: false,
        cardOrderBatches: action.payload,
        cardOrderBatchStep: 0
      }
    case CardOrderBatchActionTypes.GET_CARD_ORDER_BATCHES_FAILURE:
      return {
        ...state,
        loadingCardOrderBatches: false
      }
    case CardOrderBatchActionTypes.GET_PIN_AVAILABLE:
      return {
        ...state,
        loadingPinAvailable: true
      }
    case CardOrderBatchActionTypes.GET_PIN_AVAILABLE_SUCCESS:
      return {
        ...state,
        loadingPinAvailable: false,
        detailPinAvailable: action.payload
      }
    case CardOrderBatchActionTypes.GET_PIN_AVAILABLE_FAILURE:
      return {
        ...state,
        loadingPinAvailable: false,
      }
    case CardOrderBatchActionTypes.GET_CARD_ORDER_TO_BATCH:
      return {
        ...state,
        loadingCardOrderToBatch: true
      }
    case CardOrderBatchActionTypes.GET_CARD_ORDER_TO_BATCH_SUCCESS:
      return {
        ...state,
        loadingCardOrderToBatch: false,
        cardOrdersToBatch: action.payload
      }
    case CardOrderBatchActionTypes.GET_CARD_ORDER_TO_BATCH_FAILURE:
      return {
        ...state,
        loadingCardOrderToBatch: false,
      }
    case CardOrderBatchActionTypes.CREATE_CARD_ORDER_BATCH:
      return {
        ...state,
        loadingCreateCardOrderBatch: true
      }
    case CardOrderBatchActionTypes.CREATE_CARD_ORDER_BATCH_SUCCESS:
      return {
        ...state,
        loadingCreateCardOrderBatch: false,
        createdCardOrderBatch: action.payload
      }
    case CardOrderBatchActionTypes.CREATE_CARD_ORDER_BATCH_FAILURE:
      return {
        ...state,
        loadingCreateCardOrderBatch: false,
        cardOrderBatchErrorMsg: action.payload
      }
    case CardOrderBatchActionTypes.GENERATE_CARD_NUMBERS:
      return {
        ...state,
        loadingGenerateCard: true
      }
    case CardOrderBatchActionTypes.GENERATE_CARD_NUMBERS_SUCCESS:
      return {
        ...state,
        loadingGenerateCard: false,
        detailAssignedCardOrderBatch: action.payload,
        cardOrderBatchStep: 2
      }
    case CardOrderBatchActionTypes.GENERATE_CARD_NUMBERS_FAILURE:
      return {
        ...state,
        loadingGenerateCard: false,
        cardOrderBatchErrorMsg: action.payload
      }
    case CardOrderBatchActionTypes.CLICK_BATCH_CREATED:
      return {
        ...state,
        createdCardOrderBatch: action.payload,
        cardOrderBatchStep: 1
      }

    case CardOrderBatchActionTypes.CARD_ORDER_BATCH_STEP:
      return {
        ...state,
        cardOrderBatchStep: 2
      }

    case CardOrderBatchActionTypes.CLICK_CARD_ASSIGNED:
      return {
        ...state,
        detailAssignedCardOrderBatch: action.payload,
        cardOrderBatchStep: 2
      }

    case CardOrderBatchActionTypes.GET_DETAIL_CARD_ORDER_BATCH:
      return {
        ...state,
        loadingRemoveOrder: false,
      }
    case CardOrderBatchActionTypes.GET_DETAIL_CARD_ORDER_BATCH_SUCCESS: 
      return {
        ...state,
        detailCardOrderBatch: action.payload
      }
    case CardOrderBatchActionTypes.GET_DETAIL_CARD_ORDER_BATCH_FAILURE:
      return {
        ...state
      }
      case CardOrderBatchActionTypes.REMOVE_CARD_ORDER_BATCH:
      return {
        ...state,
        loadingRemoveCardOrder: true
      }
    case CardOrderBatchActionTypes.REMOVE_CARD_ORDER_BATCH_SUCCESS:
      return {
        ...state,
        loadingRemoveCardOrder: false,
        removeCardOrder: action.payload
      }
    case CardOrderBatchActionTypes.REMOVE_CARD_ORDER_BATCH_FAILURE:
      return {
        ...state,
        loadingRemoveCardOrder: false,
        removeCardOrderErrorMsg: action.payload
      }

    default: 
      return state
  }
};

export default CardOrderBatchReducer;
import {lazy} from 'react';

const ForgetPasswordPage = lazy(() => import('./forget-password/forget-password.component'))
const ResetPasswordPage = lazy(() => import('./reset-password/reset-password.component'))
const SigninPage = lazy(() => import('./signin/signin.component'))
const AccountPage = lazy(() => import('./account/account.component'))
const DashboardPage = lazy(() => import('./dashboard/dashboard.component'))
const CardTemplatePage = lazy(() => import('./card-template/card-template.component'))
const CardManagementPage = lazy(() => import('./card-management/card-management.component'))
const UserPage = lazy(() => import('./user/user.component'))
const AccountManagementPage = lazy(() => import('./account-management/account-management.component'))
const CardOrderPage = lazy(() => import('./card-orders/card-order.component'))
const CardReviewPage = lazy(() => import('./card-review/card-review.component'))
const CardOrderReviewPage = lazy(() => import('./card-orders/card-order-review.component'))
const ResetPasswordMessagePage = lazy(() => import('./reset-password-to-email/reset-password.component'))
const PrivacyPolicyPage = lazy(() => import('./privacy-policy/privacy-policy.component'))
const WebsiteAgreementPage = lazy(() => import('./website-agreement/website-agreement.component'))
const TermAndConditionPage = lazy(() => import('./term-and-condition/term.component'))
const TermAndConditionPublicPage = lazy(() => import('./term-and-condition/term-public.component'))
const AccountActivationPage = lazy(() => import('./account-activation/account-activation.component'))
const EditProfilePage = lazy(() => import('./edit-profile/edit-profile.component'))
const DeliveryAddressPage = lazy(() => import('./delivery-address/delivery-address.component'))
const BillingInformationPage = lazy(() => import('./billing-information/billing-information.component'))
const CardOrderBatchPage = lazy(() => import('./card-order-batch/card-order-batch.component'))
const CreateOrderBatchPage = lazy(() => import('./card-order-batch/create-order-batch.component'))
const TransactionDetailReport = lazy(() => import('./reports/transaction-detail-report.component'))
const FuelSummaryReport = lazy(() => import('./reports/fuel-summary-report.component'))
const BillingSortReport = lazy(() => import('./reports/billing-sort-report.component'))
const ListDriverOrVehicleReport = lazy(() => import('./reports/list-driver-or-vehicle-report.component'))
const ExceptionReport = lazy(() => import('./reports/exception-report.component'))
const TaxSummaryReport = lazy(() => import('./reports/tax-summary-report.component'))
const OwnerOperatorReport = lazy(() => import('./reports/owner-operator-report.component'))
const DailyTransactionReport = lazy(() => import('./reports/daily-transaction-report.component'))
const ScheduledExceptionReport = lazy(() => import('./reports/scheduled-exception-report.component'))
const InvoicedTransactionReport = lazy(() => import('./reports/invoiced-transaction-report.component'))
const DriverVehicleRecordsMonthlyAuditReport = lazy(() => import('./reports/driver-vehicle-records-monthly-audit-report.component'))
const ScheduledReportInstructions = lazy(() => import('./reports/scheduled-report-instructions.components'))
const TaxExemptReport = lazy(() => import('./reports/tax-exempt-report.component'))
const SubmitLogReport = lazy(() => import('./reports/submit-log-report.component'))
const UserLogReport = lazy(() => import('./reports/user-log-report.component'))

export {
    SigninPage,
    AccountPage,
    DashboardPage,
    CardTemplatePage,
    ForgetPasswordPage,
    UserPage,
    CardManagementPage,
    ResetPasswordPage,
    AccountManagementPage,
    CardOrderPage,
    CardReviewPage,
    CardOrderReviewPage,
    ResetPasswordMessagePage,
    PrivacyPolicyPage,
    WebsiteAgreementPage,
    TermAndConditionPage,
    TermAndConditionPublicPage,
    AccountActivationPage,
    EditProfilePage,
    DeliveryAddressPage,
    BillingInformationPage,
    CardOrderBatchPage,
    CreateOrderBatchPage,
    TransactionDetailReport,
    FuelSummaryReport,
    BillingSortReport,
    ListDriverOrVehicleReport,
    ExceptionReport,
    TaxSummaryReport,
    OwnerOperatorReport,
    DailyTransactionReport,
    ScheduledExceptionReport,
    InvoicedTransactionReport,
    DriverVehicleRecordsMonthlyAuditReport,
    ScheduledReportInstructions,
    TaxExemptReport,
    SubmitLogReport,
    UserLogReport
}
const UserActionTypes = {
    CREATE_USER: "CREATE_USER",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
    GET_USER: "GET_USER",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAILURE: "GET_USER_FAILURE",
    GET_USERS: "GET_USERS",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_FAILURE: "GET_USERS_FAILURE",
    GET_DATA: "GET_DATA",
    GET_DATA_SUCCESS: "GET_DATA_SUCCESS",
    GET_DATA_FAILURE: "GET_DATA_FAILURE",
    DELETE_USER: "DELETE_USER",
    DELETE_USER_SUCCESS: "DELET_USER_SUCCESS",
    DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
    GET_USER_TYPE_SUCCESS: "GET_USER_TYPE_SUCCESS",
    GET_USER_TYPE_FAILURE: "GET_USER_TYPE_FAILURE",
    GET_ROLES: "GET_ROLES",
    CLEAR_ROLES: "CLEAR_ROLES",
    GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
    GET_ROLES_FAILURE: "GET_ROLES_FAILURE",
    GET_ROLE_TYPES: "GET_ROLE_TYPES",
    GET_ROLE_TYPES_SUCCESS: "GET_ROLE_TYPES_SUCCESS",
    GET_ROLE_TYPES_FAILURE: "GET_ROLE_TYPES_FAILURE",
    CLOSE_POPUP: "CLOSE_POPUP",
    OPEN_POPUP: "OPEN_POPUP",
    UPDATE_STATUS: "UPDATE_STATUS",
    UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
    UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",
    RESEND_CONFIRMATION: "RESEND_CONFIRMATION",
    RESEND_CONFIRMATION_SUCCESS: "RESEND_CONFIRMATION_SUCCESS",
    RESEND_CONFIRMATION_FAILURE: "RESEND_CONFIRMATION_FAILURE",
    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE_LOADING"
  };
  
  export default UserActionTypes;
  
import CardTemplateActionTypes from './card-template.types'

const INITIAL_STATE = {
    cardTemplate: null,
    loadingCardTemplate: false,
    listVehicleTypeDescription: [
      {
        name: 'A',
        description: 'VAN'
      },
      {
        name: 'C',
        description: 'CAR'
      },
      {
        name: 'F',
        description: 'FORK LIFT'
      },
      {
        name: 'I',
        description: 'IFTA'
      },
      {
        name: 'M',
        description: 'MISC'
      },
      {
        name: 'O',
        description: 'OFFROAD VEHICLE'
      },
      {
        name: 'P',
        description: 'PTO'
      },
      {
        name: 'R',
        description: 'REFER UNIT'
      },
      {
        name: 'T',
        description: 'TRUCK'
      },
      {
        name: 'V',
        description: 'PTO 7.5%'
      },
      {
        name: 'W',
        description: 'PTO 10%'
      },
      {
        name: 'X',
        description: 'PTO 15%'
      },
      {
        name: 'Y',
        description: 'PTO 20%'
      },
      {
        name: 'Z',
        description: 'PTO 25%'
      },
    ],

    cardTemplates: {
      data: []
    },
    loadingCardTemplates: false,

    loadingCreateCardTemplate: false,
    createCardSuccess: false,

    loadingUpdateCardTemplate: false,

    loadingDeleteCardTemplate: false,
    loadingBulkDeleteCardTemplate: false,
    cardNetworks: [],
    cardNetworkTypes: [],
    cardTypes: [],
    cardCodes: [],
    driverTypes: [],
    cardSites: [],
    vehicleTypes: [],
    fuelProfiles: [],
    timeProfiles: [],
    periodGallon: [],
    periodAmount: [],
    statesOption: [],
    productTypes: [],
    listCardTemplate: [],
    cardTemplateMatrix: null,
    periodGallons: [],
    periodAmounts: []
  };
  
  const CardTemplateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
            case CardTemplateActionTypes.GET_CARD_TEMPLATES:
              return {
                ...state,
                loadingCardTemplates: true,
                createCardSuccess: false,
                loadingUpdateCardTemplate: false,
                updateCardSuccess: false,
                cardTemplates: [],
                loadingCreateCardTemplate: false
              }
            case CardTemplateActionTypes.GET_CARD_TEMPLATES_SUCCESS:
              return {
                ...state,
                loadingCardTemplates: false,
                cardTemplates: action.payload
              }
            case CardTemplateActionTypes.GET_CARD_TEMPLATES_FAILURE:
              return {
                ...state,
                loadingCardTemplates: false,
                cardTemplates: []
              }



            case CardTemplateActionTypes.GET_CARD_TEMPLATE:
              return {
                ...state,
                loadingCardTemplate: true,
              }
            case CardTemplateActionTypes.GET_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                loadingCardTemplate: false,
                cardTemplate: action.payload
              }
            case CardTemplateActionTypes.GET_CARD_TEMPLATE_FAILURE:
              return {
                ...state,
                loadingCardTemplate: false,
              }



            case CardTemplateActionTypes.CREATE_CARD_TEMPLATE:
              return {
                ...state,
                loadingCreateCardTemplate: true,
              }
            case CardTemplateActionTypes.CREATE_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                loadingCreateCardTemplate: false,
                createCardSuccess: true,
              }
            case CardTemplateActionTypes.CREATE_CARD_TEMPLATE_FAILURE:
              return {
                ...state,
                loadingCreateCardTemplate: false,
              }


          
            case CardTemplateActionTypes.UPDATE_CARD_TEMPLATE:
              return {
                ...state,
                loadingCreateCardTemplate: true,
              }
            case CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                loadingCreateCardTemplate: false,
                createCardSuccess: true
              }
            case CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_FAILURE:
              return {
                ...state,
                loadingCreateCardTemplate: false,
              }



           case CardTemplateActionTypes.DELETE_CARD_TEMPLATE:
              return {
                ...state,
                loadingDeleteCardTemplate: true,
              }
            case CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                loadingDeleteCardTemplate: false,
              }
            case CardTemplateActionTypes.DELETE_CARD_TEMPLATE_FAILURE:
              return {
                ...state,
                loadingDeleteCardTemplate: false,
              }

            case CardTemplateActionTypes.GET_EDIT_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                cardNetworks: action.payload.cardNetworks,
                cardCodes: action.payload.cardCodes,
                cardTypes: action.payload.cardTypes,
                // driverTypes: action.payload.driverTypes,
                // cardSites: action.payload.cardSites,
                // vehicleTypes: action.payload.vehicleTypes,
                // fuelProfiles: action.payload.fuelProfiles,
                // timeProfiles: action.payload.timeProfiles,
                // periodGallon: action.payload.periodGallon,
                // periodAmount: action.payload.periodAmount,
                // statesOption: action.payload.statesOption,
                // productTypes: action.payload.productTypes,
              }

            case CardTemplateActionTypes.GET_EDIT_CARD_TEMPLATE_FAILURE:
              return {
                ...state
              }

            case CardTemplateActionTypes.GET_LIST_CARD_TEMPLATE:
              return {
                ...state
              }
            case CardTemplateActionTypes.GET_LIST_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                listCardTemplate: action.payload
              }
            case CardTemplateActionTypes.GET_LIST_CARD_TEMPLATE_FAILURE:
              return {
                ...state
              }

            case CardTemplateActionTypes.GET_CARD_NETWORK:
              return {
                ...state
              }
            case CardTemplateActionTypes.GET_CARD_NETWORK_SUCCESS:
              return {
                ...state,
                cardNetworks: action.payload.data
              }
            case CardTemplateActionTypes.GET_CARD_NETWORK_FAILURE:
              return {
                ...state
              }

            case CardTemplateActionTypes.GET_CARD_NETWORK_TYPE:
              return {
                ...state
              }
            case CardTemplateActionTypes.GET_CARD_NETWORK_TYPE_SUCCESS:
              return {
                ...state,
                cardNetworkTypes: action.payload.data
              }
            case CardTemplateActionTypes.GET_CARD_NETWORK_TYPE_FAILURE:
              return {
                ...state
              }

            case CardTemplateActionTypes.GET_CARD_TEMPLATE_MATRIX:
              return {
                ...state,
                cardTemplateMatrix: null
              }
            case CardTemplateActionTypes.GET_CARD_TEMPLATE_MATRIX_SUCCESS:
              return {
                ...state,
                cardTemplateMatrix: action.payload.data
              }
            case CardTemplateActionTypes.GET_CARD_TEMPLATE_MATRIX_FAILURE:
              return {
                ...state,
                cardTemplateMatrix: null
              }

            case CardTemplateActionTypes.GET_STATE_CODE:
              return {
                ...state
              }
            case CardTemplateActionTypes.GET_STATE_CODE_SUCCESS:
              return {
                ...state,
                statesOption: action.payload.data
              }
            case CardTemplateActionTypes.GET_STATE_CODE_FAILURE:
              return {
                ...state
              }

            case CardTemplateActionTypes.GET_CARD_PERIOD_GALLON:
              return {
                ...state,
                periodGallons: []
              }
            case CardTemplateActionTypes.GET_CARD_PERIOD_GALLON_SUCCESS:
              return {
                ...state,
                periodGallons: action.payload
              }
            case CardTemplateActionTypes.GET_CARD_PERIOD_GALLON_FAILURE:
              return {
                ...state,
                periodGallons: []
              }

            case CardTemplateActionTypes.GET_CARD_PERIOD_AMOUNT:
              return {
                ...state,
                periodAmounts: []
              }
            case CardTemplateActionTypes.GET_CARD_PERIOD_AMOUNT_SUCCESS:
              return {
                ...state,
                periodAmounts: action.payload
              }
            case CardTemplateActionTypes.GET_CARD_PERIOD_AMOUNT_FAILURE:
              return {
                ...state,
                periodAmounts: []
              }

            case CardTemplateActionTypes.GET_VEHICLE_TYPE:
              return {
                ...state,
                vehicleTypes: []
              }
            case CardTemplateActionTypes.GET_VEHICLE_TYPE_SUCCESS:
              return {
                ...state,
                vehicleTypes: action.payload
              }
            case CardTemplateActionTypes.GET_VEHICLE_TYPE_FAILURE:
              return {
                ...state,
                vehicleTypes: []
              }

            case CardTemplateActionTypes.GET_TIME_PROFILE:
              return {
                ...state,
                timeProfiles: []
              }
            case CardTemplateActionTypes.GET_TIME_PROFILE_SUCCESS:
              return {
                ...state,
                timeProfiles: action.payload
              }
            case CardTemplateActionTypes.GET_TIME_PROFILE_FAILURE:
              return {
                ...state,
                timeProfiles: []
              }

            case CardTemplateActionTypes.GET_FUEL_PROFILE:
              return {
                ...state,
                fuelProfiles: []
              }
            case CardTemplateActionTypes.GET_FUEL_PROFILE_SUCCESS:
              return {
                ...state,
                fuelProfiles: action.payload
              }
            case CardTemplateActionTypes.GET_FUEL_PROFILE_FAILURE:
              return {
                ...state,
                fuelProfiles: []
              }

            case CardTemplateActionTypes.BULK_DELETE_CARD_TEMPLATE:
              return {
                ...state,
                loadingBulkDeleteCardTemplate: true
              }
            case CardTemplateActionTypes.BULK_DELETE_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                loadingBulkDeleteCardTemplate: false
              }
            case CardTemplateActionTypes.BULK_DELETE_CARD_TEMPLATE_FAILURE:
              return {
                ...state,
                loadingBulkDeleteCardTemplate: false
              }
          
          default: return state
    }
  };
  
  export default CardTemplateReducer;
  
import { Spinner } from 'react-bootstrap';
import "./loading.styles.scss"

const Loading = () => {
  return (
    <div className="loading">
      <Spinner animation="border" variant="danger" />
    </div>
  )
}

export default Loading
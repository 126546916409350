import axios from '../../axiosInstance';
import  CardActionTypes  from './card.types';
import UserActionTypes from '../user/user.types';
import { batch } from 'react-redux';
import { tokenExpired } from '../authentication/auth.actions'
import { showLoading } from '../user/user.actions'

import fileDownload from 'js-file-download';

const checkCardsUrl = '/v1/check_cards';
const cardsUrl = '/v1/cards';
const cardUrl = '/v1/cards/'
const updateCardUrl = '/v1/cards/'
const deleteCardUrl = '/v1/card/';
const updateStatusCardUrl = '/v1/update_card_status'
const bulkUpdateCardUrl = '/v1/cards/update_multiple_card'
const bulkUploadCardUdl = '/v1/upload_bulk_card_order'
const downloadCardTemplateSampleUrl = '/v1/download_template_card_upload'
const allCardsUrl = '/v1/get_card_by_account/'


export const getCards = ({keyword = null, card_type_id = null, card_network_id = null, card_status_id = null, orderBy = null, sortedBy = null, page = 1, per_page=10}) =>  {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
    const account = state.auth.account

    try {
      dispatch({
        type: CardActionTypes.GET_CARDS
      })

      const res = await axios.get(
        cardsUrl,
        {
          params: { keyword, card_type_id, card_network_id, card_status_id, orderBy, sortedBy, page, per_page },
          headers: {
            Authorization: 'Bearer ' + token,
            AccountId:  account.account.id
          }
        }
      )
        // console.log(res)
      dispatch({
        type: CardActionTypes.GET_CARDS_SUCCESS,
        payload: res.data,
      })

    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: CardActionTypes.GET_CARDS_FAILURE
      })
    }
  }
}

export const getCard = (cardId) =>  {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
    const account = state.auth.account

    try {
      dispatch({
        type: CardActionTypes.GET_CARD
      })

    
      const res = await axios.get(
        `${cardUrl}${cardId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            AccountId:  account.account.id
          }
        }
      )     
   
      dispatch({
        type: CardActionTypes.GET_CARD_SUCCESS,
        payload: res.data.data
      })

      return res.data.data

    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: CardActionTypes.GET_CARD_FAILURE
      })
    }
  }
}

export const getAllCards = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
    const account = state.auth.account

    try {
      dispatch({
        type: CardActionTypes.GET_CARDS
      })

      const res = await axios.get(
        `${allCardsUrl}${account.account.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
        console.log(res)
      dispatch({
        type: CardActionTypes.GET_CARDS_SUCCESS,
        payload: res.data,
      })

    } catch (err) {
      console.log(err)
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: CardActionTypes.GET_CARDS_FAILURE
      })
    }
  }
}

export const checkCreateCard = (card) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardActionTypes.CREATE_CARD
        })
      
        const res = await axios.post(
          checkCardsUrl,
          card,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id
            }
          }
        )
          
        dispatch(removeUploadedCardData())

        dispatch({
          type: CardActionTypes.CREATE_CARD_SUCCESS,
          payload: res.data.data
        })
        return res.data

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }

        let error = err.response.data;

        for (let key in error.errors){
          error.errors[key].forEach(function(item,index){
            if(error.errors[key][index].includes('vehicle number has already been taken')){
              error.errors[key][index] = 'Vehicle number has already been taken';
            }
          });
        }

        dispatch({
          type: CardActionTypes.CREATE_CARD_FAILURE,
          payload: error
        });

        // batch(() => {
          // dispatch({
          //   type: CardActionTypes.CREATE_CARD_FAILURE,
          //   payload: err.response.data
          // })
          // dispatch({
          //   type: UserActionTypes.OPEN_POPUP,
          //   payload: {
          //     email: "",
          //     status: CardActionTypes.CREATE_CARD_FAILURE,
          //     error: true,
          //     withActionButton: false,
          //   }
          // });
        // })
        return err.response
      }
    }
  }
export const createCard = (card) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardActionTypes.CREATE_CARD
        })

        const res = await axios.post(
          cardsUrl,
          card,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id
            }
          }
        )

        dispatch(removeUploadedCardData())

        dispatch({
          type: CardActionTypes.CREATE_CARD_SUCCESS,
          payload: res.data.data
        })
        return res.data

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: CardActionTypes.CREATE_CARD_FAILURE,
            payload: err.response.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: "",
              status: CardActionTypes.CREATE_CARD_FAILURE,
              error: true,
              withActionButton: false,
            }
          });
        })
        return err.response
      }
    }
  }

  export const updateCard = (card, cardId) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardActionTypes.UPDATE_CARD
        })

      
        const res = await axios.put(
          `${updateCardUrl}${cardId}`,
          card,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id,
            }
          }
        )

        batch(() => {
          dispatch({
            type: CardActionTypes.UPDATE_CARD_SUCCESS,
            payload: res.data.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: '',
              status: CardActionTypes.UPDATE_CARD_SUCCESS,
              error: false
            }
          });
        });
        return res.data

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: CardActionTypes.UPDATE_CARD_FAILURE,
            payload: err.response.data
          })
        })
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: '',
              status: CardActionTypes.UPDATE_CARD_FAILURE,
              error: true
            }
        })
        return err.response
      }
    }
  }
  
  export const deleteCard= ({cardId= null}) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token

      try {
        dispatch({
          type: CardActionTypes.DELETE_CARD
        })

      
        const res = await axios.delete(
          `${deleteCardUrl}${cardId}`,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
  
        dispatch({
          type: CardActionTypes.DELETE_CARD_SUCCESS,
          payload: res.data.data
        })

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardActionTypes.DELETE_CARD_FAILURE
        })
      }
    }
  }
  
  
  export const getEditCard = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token

      try {
      
        // const cardTypes = await axios.get(
        //   cardTypesUrl,
        //   {
        //     headers: {
        //       Authorization: 'Bearer ' + token
        //     }
        //   }
        // )

        // const cardCodes = await axios.get(
        //   cardCodesUrl,
        //   {
        //     headers: {
        //       Authorization: 'Bearer ' + token
        //     }
        //   }
        // )

        // const cardNetworks = await axios.get(
        //   cardNetworksUrl,
        //   {
        //     headers: {
        //       Authorization: 'Bearer ' + token
        //     }
        //   }
        // )
  
        dispatch({
          type: CardActionTypes.GET_EDIT_CARD_SUCCESS,
          payload: {
            // cardCodes: cardCodes.data.data,
            // cardTypes: cardTypes.data.data,
            // cardNetworks: cardNetworks.data.data
          }
        })

      } catch (err) {
        dispatch({
          type: CardActionTypes.GET_EDIT_CARD_FAILURE
        })
      }
    }
  }

  export const deactivateCards = (cards) => {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
      
      try {
        batch(() => {
          dispatch({
            type: CardActionTypes.UPDATE_CARD_STATUS
          })
          dispatch(showLoading(true))
        })

        const res = await axios.post(
          `${updateStatusCardUrl}`,
          cards,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id,
            }
          }
        )
        batch(() => {
          dispatch({
            type: CardActionTypes.UPDATE_CARD_STATUS_SUCCESS,
            payload: res.data.data
          })
          dispatch(showLoading(false))
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: '',
              status: CardActionTypes.UPDATE_CARD_STATUS_SUCCESS,
              error: false
            }
          })
        })
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: CardActionTypes.UPDATE_CARD_STATUS_FAILURE,
            payload: err.response.data
          })
          dispatch(showLoading(false))
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: '',
              status: CardActionTypes.UPDATE_CARD_STATUS_FAILURE,
              error: true
            }
          })
        })
      }
    }
  }

  export const bulkUpdateCard = (cards) => {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        batch(() => {
          dispatch({
            type: CardActionTypes.BULK_UPDATE_CARD
          })
          dispatch(showLoading(true))
        })

        const res = await axios.post(
          bulkUpdateCardUrl,
          cards,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id,
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
        batch(() => {
          dispatch(showLoading(false))
          dispatch({
            type: CardActionTypes.BULK_UPDATE_CARD_SUCCESS,
            payload: res.data.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: '',
              status: CardActionTypes.BULK_UPDATE_CARD_SUCCESS,
              error: false
            }
          })
        })

        return res

      }catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch(showLoading(false))
          dispatch({
            type: CardActionTypes.BULK_UPDATE_CARD_FAILURE,
            payload: err.response.data
          })
        })
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: '',
              status: CardActionTypes.BULK_UPDATE_CARD_FAILURE,
              error: true
            }
        })
        return err.response
      }
    }
  }

  export const bulkUploadCard = (file) => {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardActionTypes.BULK_UPLOAD_CARD
        })

        const res = await axios.post(
          bulkUploadCardUdl,
          file,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        // console.log(`res.data.data`, res.data.data)
        batch(() => {
          dispatch({
            type: CardActionTypes.BULK_UPLOAD_CARD_SUCCESS,
            payload: res.data.data
          })
          // dispatch({
          //   type: UserActionTypes.OPEN_POPUP,
          //   payload: {
          //     email: '',
          //     status: CardActionTypes.BULK_UPDATE_CARD_SUCCESS,
          //     error: false
          //   }
          // })
        })
        return res
      }catch (err) {
        // console.log(`err.response`, err.response)
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: CardActionTypes.BULK_UPLOAD_CARD_FAILURE,
            payload: err.response.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
              payload: {
                email: '',
                status: CardActionTypes.BULK_UPDATE_CARD_FAILURE,
                error: true
              }
          })
        })
        return err.response
      }
    }
  }

  export const removeUploadedCardData = () => {
    return (dispatch) => {
      dispatch({
        type: CardActionTypes.REMOVE_UPLOADED_CARD_DATA
      })
    }
  }

  export const downloadCardTemplateSample = (cardTemplateId, templateName) => {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardActionTypes.BULK_UPDATE_CARD
        })

        const res = await axios.post(
          downloadCardTemplateSampleUrl,
          cardTemplateId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id,
              'Access-Control-Allow-Origin': '*'
            },
            responseType: 'blob'
          }
        )
        fileDownload(res.data, templateName + '.xls')
        
      }catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
      }
    }
  }



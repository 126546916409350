import dashboardTypes from "./dashboard.types";

const INITIAL_STATE = {
    isLoading: false,
    dashboardData: {},
    productGrade: {},
    products: [],
    notification: '',
    isLoadingProduct: false,
    isLoadingNotification: false,
    cardsInAccount: [],
}

const DashboardReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case dashboardTypes.GET_DASHBOARD_DATA:
            return{
                ...state,
                isLoading: true
            }
        case dashboardTypes.GET_DASHBOARD_DATA_SUCCESS:
            return{
                ...state,
                isLoading: false,
                dashboardData: action.payload.data
            }
        case dashboardTypes.GET_DASHBOARD_DATA_FAILURE:
            return{
                ...state,
                isLoading: false,
                dashboardData: {}
            }
        case dashboardTypes.GET_PRODUCT_GRADE:
            return{
                ...state,
                isLoadingProduct: false,
                products: [],
                productGrade: {}
            }
        case dashboardTypes.GET_PRODUCT_GRADE_SUCCESS:
            return{
                ...state,
                isLoadingProduct: false,
                products: state.products.concat(action.payload.dataProducts),
                productGrade: action.payload
            }
        case dashboardTypes.GET_PRODUCT_GRADE_FAILURE:
            return{
                ...state,
                isLoadingProduct: false,
                products:  [],
                productGrade: {}
            }
        case dashboardTypes.GET_NOTIFICATION:
            return{
                ...state,
                isLoadingNotification: true,
                notification: ''
            }
        case dashboardTypes.GET_NOTIFICATION_SUCCESS:
            return{
                ...state,
                isLoadingNotification: false,
                notification: action.payload,
            }
        case dashboardTypes.GET_NOTIFICATION_FAILURE:
            return{
                ...state,
                isLoadingNotification: false,
                notification: ''
            }
        case dashboardTypes.GET_CARDS_IN_ACCOUNT_SUCCESS:
            return{
                ...state,
                cardsInAccount: action.payload
            }
        case dashboardTypes.GET_CARDS_IN_ACCOUNT_FAILURE:
            return{
                ...state,
                cardsInAccount: []
            }
        default: return state
    }
}

export default DashboardReducer;
import "./popup-action.styles.scss";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { BsFillGearFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../redux/user/user.actions";
import UserActionTypes from "../../redux/user/user.types";
import AuthActionTypes from "../../redux/authentication/auth.types"
import DeliveryActionTypes from "../../redux/delivery-address/delivery-address.types";
import CardTemplateActionTypes from "../../redux/card-template/card-template.types";
import CardOrderBatchActionTypes from '../../redux/card-order-batch/card-order-batch.types'
import BillingActionTypes from "../../redux/delivery-address/billing-information.types";
import reportTypes from "../../redux/report/report.types";
import { Button } from "react-bootstrap";
import CardActionTypes from "../../redux/card/card.types";
import CardOrderActionTypes from "../../redux/card-order/card-order.types"

const PopupAction = (props) => {
  const dispatch = useDispatch();
  const { cardOrderBatchErrorMsg } = useSelector((state) => state.cardOrderBatch)
  const { cardOrderErrorMsg } = useSelector((state) => state.cardOrder)
  const { cardErrorMsg } = useSelector((state) => state.card)
  const { forgetPasswordErrorMsg } = useSelector((state) => state.auth)

  const generateText = (email, status) => {
    switch (status) {
      case 0:
        return `We've enable ${email}.`;
      case 1:
        return `We've Disable ${email}.`;
      case 2:
        return `We've sent invitation to ${email}.`;
      case 3:
        return `We've asked ${email} to accept Terms and Conditions.`;
      case UserActionTypes.RESEND_CONFIRMATION_FAILURE:
        return `Something went wrong!`;
      case UserActionTypes.UPDATE_STATUS_FAILURE:
        return `Something went wrong!`;
      case UserActionTypes.CREATE_USER_SUCCESS:
        return `We've sent an invitation to ${email}.`;
      case UserActionTypes.CREATE_USER_FAILURE:
        return `Something went wrong!`;
      case UserActionTypes.UPDATE_USER_SUCCESS:
        return `Data has been saved!`;
      case UserActionTypes.UPDATE_USER_FAILURE:
          return `Something went wrong`;
      case DeliveryActionTypes.CREATE_DELIVERY_SUCCESS:
        return `Data has been saved!`;
      case DeliveryActionTypes.CREATE_DELIVERY_FAILURE:
        return `Something went wrong!`;
      case DeliveryActionTypes.UPDATE_DELIVERY_SUCCESS:
        return `Data has been saved!`;
      case DeliveryActionTypes.UPDATE_DELIVERY_FAILURE:
        return `Something went wrong!`;
      case CardTemplateActionTypes.CREATE_CARD_TEMPLATE_SUCCESS:
        return `New card template was created`;
      case CardTemplateActionTypes.CREATE_CARD_TEMPLATE_FAILURE:
        return `Something went wrong!`;
      case CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_SUCCESS:
        return `Card template updated`;
      case CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_FAILURE:
        return `Something went wrong!`;
      case CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS:
        return `Card template deactivated`;
      case CardTemplateActionTypes.DELETE_CARD_TEMPLATE_FAILURE:
        return `Something went wrong!`;
      case "confirm_change_network_card_template":
        return `If you proceed with this change, all the values below will be reset.`;
      case "confirm_delete_card_template":
        return `Are you sure you want to deactivate this card template?`
      case CardActionTypes.CREATE_CARD_FAILURE:
          return ErrorMessage(cardErrorMsg);
      case CardActionTypes.UPDATE_CARD_SUCCESS:
        return `Card successfully updated`;
      case CardActionTypes.UPDATE_CARD_FAILURE:
        return ErrorMessage(cardErrorMsg);
      case CardActionTypes.UPDATE_CARD_STATUS_SUCCESS:
          return `Card status has been updated`;
      case CardActionTypes.UPDATE_CARD_STATUS_FAILURE:
          return ErrorMessage(cardErrorMsg);
      case CardActionTypes.BULK_UPDATE_CARD_SUCCESS:
        return `Requests submitted to this site will be processed during business hours, M-F from 8 am to 5 pm`;
      case CardActionTypes.BULK_UPDATE_CARD_FAILURE:
        return ErrorMessage(cardErrorMsg);
      case CardActionTypes.BULK_UPLOAD_CARD_FAILURE:
        return ErrorMessage(cardErrorMsg);
      case 'UNAUTHORIZED':
        return `403 Unauthorized`;
      case BillingActionTypes.UPDATE_BILLING_INFORMATION_SUCCESS:
        return `Your changes have been saved`;
      case BillingActionTypes.UPDATE_BILLING_INFORMATION_FAILURE:
        return `Something went wrong` ;
      case 'delete_card_confirmation':
        return `Are you sure you want delete this delivery address?`
      case DeliveryActionTypes.DELETE_DELIVERY_SUCCESS:
        return `Delivery Address deleted`;
      case DeliveryActionTypes.DELETE_DELIVERY_FAILURE:
        return `Something Went Wrong`;
      case 'Complete_Order_Batch_Success':
        return `Card order batches have been completed`
      case 'Complete_Order_Batch_Failure':
        return `Something Wen Wrong`
      case 'card_must_be_at_least_one':
        return `Card must be at least one`
      case 'token_expired':
        return `Your session has expired, please login again`
      case CardOrderBatchActionTypes.CREATE_CARD_ORDER_BATCH_FAILURE:
        return ErrorMessage(cardOrderBatchErrorMsg)
      case CardOrderBatchActionTypes.GENERATE_CARD_NUMBERS_FAILURE:
        return ErrorMessage(cardOrderBatchErrorMsg)
      case CardOrderActionTypes.UPDATE_CARD_ORDERS_REVIEW_FAILURE:
        return ErrorMessage(cardOrderErrorMsg);
      case CardOrderActionTypes.CONFIRM_DELIVERY_CARD_ORDERS_FAILURE:
        return ErrorMessage(cardOrderErrorMsg);
      case reportTypes.CREATE_REPORT_FAILURE:
        return `Email has been registered`
      case AuthActionTypes.RESET_PASSWORD_FAILURE:
        return forgetPasswordErrorMsg
      case 'delete_order_from_batch_confirmation':
        return `Are you sure you want to remove this order from the batch?`
      default:
        break;
    }
  };

  const generateTitle = (status) => {
    if (
      status == 0 ||
      status == 1 ||
      status == 2 ||
      status == 3 ||
      status == UserActionTypes.RESEND_CONFIRMATION_FAILURE ||
      status == UserActionTypes.UPDATE_STATUS_FAILURE ||
      status == UserActionTypes.CREATE_USER_SUCCESS ||
      status == UserActionTypes.CREATE_USER_FAILURE
    ) {
      return ``;
    }
    if (
      status == DeliveryActionTypes.CREATE_DELIVERY_SUCCESS ||
      status == DeliveryActionTypes.CREATE_DELIVERY_FAILURE ||
      status == DeliveryActionTypes.UPDATE_DELIVERY_SUCCESS ||
      status == DeliveryActionTypes.UPDATE_DELIVERY_FAILURE
    ) {
      return `Delivery Address`;
    }
    if (
      status == CardTemplateActionTypes.CREATE_CARD_TEMPLATE_SUCCESS ||
      status == CardTemplateActionTypes.CREATE_CARD_TEMPLATE_FAILURE ||
      status == CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_SUCCESS ||
      status == CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_FAILURE ||
      status == CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS ||
      status == CardTemplateActionTypes.DELETE_CARD_TEMPLATE_FAILURE ||
      status == "confirm_change_network_card_template" ||
      status == "confirm_delete_card_template"
    ) {
      return `Card Template`;
    }
    if(status == reportTypes.CREATE_REPORT_FAILURE){
      return `Report`
    }
  };

  const ErrorMessage = (error) => {
    return (
      <div>
        <p>{error.message}</p>
        {
          error.hasOwnProperty('errors') && (
            error.errors && (
              <p>{JSON.stringify(error.errors)}</p>
            )
          )
        }
      </div>
    )
  }

  return (
    <div
      className="popup-action"
      onClick={() => {
        if (!props.data.withActionButton) {
          dispatch(closePopup());
        }
      }}
    >
      <div
        className="popup-container"
        style={{
          height: !props.data.withActionButton ? "300px" : "330px",
        }}
      >
        <div className="popup-header">
          <div className="title">{generateTitle(props.data.status)}</div>
          <div className="close-btn" onClick={() => dispatch(closePopup())}>
            Close <AiOutlineClose />
          </div>
        </div>
        <div className="popup-body">
          <div className="icon-container">
            <BsFillGearFill className="icon-popup" />
          </div>
          {!props.data.withActionButton ? (
            props.data && props.data.error ? (
              <div className="failure">Oops!</div>
            ) : (
              <div className="success">Success!</div>
            )
          ) : (
            ""
          )}
          <div className={!props.data.withActionButton ? "text" : "failure"}>
            {generateText(props.data.email, props.data.status)}
          </div>
          {props.data.withActionButton && (
            <div className="button-container">
              <Button
                className="cancel"
                variant="outline-light"
                onClick={() => {
                  dispatch(closePopup());
                }}
              >
                CANCEL
              </Button>

              <Button variant="outline-danger" onClick={() => {
                props.data.confirmAction();
                dispatch(closePopup());
              }}>
                CONTINUE
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupAction;

import { batch } from 'react-redux';
import axios from '../../axiosInstance';
import UserActionTypes from '../user/user.types';
import { tokenExpired } from '../authentication/auth.actions'
import  CardTemplateActionTypes  from './card-template.types';

const cardTemplatesUrl = '/v1/card_templates';
const deleteCardTemplateUrl = '/v1/card_templates/';
const bulkDeleteCardTemplateUrl = '/v1/delete_bulk_card_template';

// master data
const cardTypesUrl = '/v1/card_types';
const cardCodesUrl = '/v1/card_codes'
const cardNetworksUrl = '/v1/card_networks';
const cardNetworkTypeUrl = '/v1/network_card_types'
// const driverTypeUrl = '/v1/card_type_drivers';
// const cardSiteUrl = '/v1/card_site_groups';
const vehicleTypeUrl = '/v1/card_vehicle_types';
const fuelProfileUrl = '/v1/fuel_profiles';
const timeProfileUrl = '/v1/card_time_profiles';
// const periodGallonUrl = '/v1/card_period_gallons';
// const periodAmountUrl = '/v1/card_period_amounts';
const statesOptionUrl = '/v1/state_countries';
// const productTypesUrl = '/v1/card_product_types';
const zipCodesUrl = '/v1/zipcodes?keyword='
const listCardTemplateUrl = '/v1/get_list_card_templates'
const cardTemplateMatrix = '/v1/get_matrix_ct'
const cardPeriodGallonUrl = '/v1/card_period_gallons'
const cardPeriodAmountUrl = '/v1/card_period_amounts'


export const getCardTemplates = ({keyword= null, is_delete=null, page= 1, orderBy = null, sortedBy = null, is_page = null, account_id = null }) =>  {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
    const account = state.auth.account
  
    try {
      dispatch({
        type: CardTemplateActionTypes.GET_CARD_TEMPLATES
      })

      const res = await axios.get(
        cardTemplatesUrl,
        {
          params: { keyword, is_delete, page: page, orderField: orderBy, sortedField: sortedBy, is_page },
          headers: {
            Authorization: 'Bearer ' + token,
            AccountId: account_id !== null ? account_id : account.account.id
          }
        }
      )

      dispatch({
        type: CardTemplateActionTypes.GET_CARD_TEMPLATES_SUCCESS,
        payload: res.data,
      })

    } catch (err) {
      
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      else if (err.response.status === 403) {
        dispatch({
          type: UserActionTypes.OPEN_POPUP,
          payload: {
            status: 'UNAUTHORIZED',
            error: true,
          }
        });
      } else {
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_TEMPLATES_FAILURE
        })
      }
    }
  }
}

export const getCardTemplate = (templateId) =>  {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
    const account = state.auth.account

    try {
      dispatch({
        type: CardTemplateActionTypes.GET_CARD_TEMPLATE
      })

    
      const res = await axios.get(
        `${deleteCardTemplateUrl}${templateId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            AccountId: account.account.id
          }
        }
      )     
   
      dispatch({
        type: CardTemplateActionTypes.GET_CARD_TEMPLATE_SUCCESS,
        payload: res.data.data
      })

    } catch (err) {
      dispatch({
        type: CardTemplateActionTypes.GET_CARD_TEMPLATE_FAILURE
      })
    }
  }
}

export const createCardTemplate = (cardTemplate) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
      try {
        dispatch({
          type: CardTemplateActionTypes.CREATE_CARD_TEMPLATE
        })
        
        const res = await axios.post(
          cardTemplatesUrl,
          cardTemplate,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id
            }
          }
        )
  
        batch(() => {
          dispatch({
            type: CardTemplateActionTypes.CREATE_CARD_TEMPLATE_SUCCESS,
            payload: res.data.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              status: CardTemplateActionTypes.CREATE_CARD_TEMPLATE_SUCCESS,
              error: false,
            }
          });
        });

        return res

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        } else if (err.response.status === 403) {
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              status: 'UNAUTHORIZED',
              error: true,
            }
          });
        } else {
          batch(() => {
            dispatch({
              type: CardTemplateActionTypes.CREATE_CARD_TEMPLATE_FAILURE,
            })
            dispatch({
              type: UserActionTypes.OPEN_POPUP,
              payload: {
                status: CardTemplateActionTypes.CREATE_CARD_TEMPLATE_FAILURE,
                error: true
              }
            });
          });
        }
        return err.response
      }
    }
  }

  export const updateCardTemplate = (cardTemplate, cardTemplateId) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardTemplateActionTypes.UPDATE_CARD_TEMPLATE
        })

      
        const res = await axios.put(
          `${deleteCardTemplateUrl}${cardTemplateId}`,
          cardTemplate,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id
            }
          }
        )

        batch(() => {
          dispatch({
            type: CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_SUCCESS,
            payload: res.data.data
          });
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: "",
              status: CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_SUCCESS,
              error: false,
              withActionButton: false,
            }
          });
        });
        
        return res

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_FAILURE
          });
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: "",
              status: CardTemplateActionTypes.UPDATE_CARD_TEMPLATE_FAILURE,
              error: true,
              withActionButton: false,
            }
          });
        });
        return err.response
      }
    }
  }
  
  export const deleteCardTemplate = (templateId) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardTemplateActionTypes.DELETE_CARD_TEMPLATE
        })

      
        const res = await axios.delete(
          `${deleteCardTemplateUrl}${templateId}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id
            }
          }
        )
  
        batch(() => {
          dispatch({
            type: CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS,
            payload: res.data.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: "",
              status: CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS,
              error: false,
              withActionButton: false,
            }
          })
        })

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: CardTemplateActionTypes.DELETE_CARD_TEMPLATE_FAILURE
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: "",
              status: CardTemplateActionTypes.DELETE_CARD_TEMPLATE_FAILURE,
              error: true,
              withActionButton: false,
            }
          })
        })
      }
    }
  }
  
  
  export const getEditCardTemplate = () =>  {
    return async (dispatch, getState) => {
      const state = getState();
      const token = state.auth.token;
      const headers = {headers: {
        Authorization: 'Bearer ' + token
      }}

      try {
        const cardTypes = await axios.get(cardTypesUrl, headers)
        const cardCodes = await axios.get(cardCodesUrl, headers)
        const cardNetworks = await axios.get(cardNetworksUrl, headers)
        // const driverTypes = await axios.get(driverTypeUrl, headers)
        // const cardSites = await axios.get(cardSiteUrl, headers)
        // const vehicleTypes = await axios.get(vehicleTypeUrl, headers)
        // const fuelProfiles = await axios.get(fuelProfileUrl, headers)
        // const timeProfiles = await axios.get(timeProfileUrl, headers)
        // const periodGallon = await axios.get(periodGallonUrl, headers)
        // const periodAmount = await axios.get(periodAmountUrl, headers)
        // const statesOption = await axios.get(statesOptionUrl, headers)
        // const productTypes = await axios.get(productTypesUrl, headers)
        dispatch({
          type: CardTemplateActionTypes.GET_EDIT_CARD_TEMPLATE_SUCCESS,
          payload: {
            cardCodes: cardCodes.data.data,
            cardTypes: cardTypes.data.data,
            cardNetworks: cardNetworks.data.data,
            // driverTypes: driverTypes.data.data,
            // cardSites: cardSites.data.data,
            // vehicleTypes: vehicleTypes.data.data,
            // fuelProfiles: fuelProfiles.data.data,
            // timeProfiles: timeProfiles.data.data,
            // periodGallon: periodGallon.data.data,
            // periodAmount: periodAmount.data.data,
            // statesOption: statesOption.data.data,
            // productTypes: productTypes.data.data,
          }
        })

      } catch (err) {
        dispatch({
          type: CardTemplateActionTypes.GET_EDIT_CARD_TEMPLATE_FAILURE
        })
      }
    }
  }

  export const loadZipCodes = (keyword) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
      
        const res = await axios.get(
          `${zipCodesUrl}${keyword}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )
        return res.data.data
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        return []
      }
    }
  }

  export const loadStateCode = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_STATE_CODE
        })
  
      
        const res = await axios.get(
          `${statesOptionUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )
     
        dispatch({
          type: CardTemplateActionTypes.GET_STATE_CODE_SUCCESS,
          payload: res.data
        })
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_STATE_CODE_FAILURE
        })
      }
    }
  }
  
  export const getCardNetwork = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_NETWORK
        })
  
      
        const res = await axios.get(
          `${cardNetworksUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )     
     
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_NETWORK_SUCCESS,
          payload: res.data
        })
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_NETWORK_FAILURE
        })
      }
    }
  }

  export const getCardNetworkType = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_NETWORK_TYPE
        })
  
      
        const res = await axios.get(
          `${cardNetworkTypeUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )     
     
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_NETWORK_TYPE_SUCCESS,
          payload: res.data
        })
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_NETWORK_TYPE_FAILURE
        })
      }
    }
  }

  export const getCardTemplateMatrix = ({ network = null, network_card_type = null }) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_TEMPLATE_MATRIX
        })
  
      
        const res = await axios.get(
          `${cardTemplateMatrix}`,
          {
            params: {network, network_card_type},
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )

        dispatch({
          type: CardTemplateActionTypes.GET_CARD_TEMPLATE_MATRIX_SUCCESS,
          payload: res.data
        })
        return res
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_TEMPLATE_MATRIX_FAILURE
        })
        return err.response
      }
    }
  }

  export const getCardPeriodGallon = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_PERIOD_GALLON
        })
  
      
        const res = await axios.get(
          `${cardPeriodGallonUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )

        let listCardPeriodGallon = []
        res.data.data.map((periodGallon) => {
          if (periodGallon.name !== 'N/A' &&
              periodGallon.name !== '') {
            listCardPeriodGallon.push(periodGallon)
          }
        })

        if (res.status === 200) {
          dispatch({
            type: CardTemplateActionTypes.GET_CARD_PERIOD_GALLON_SUCCESS,
            payload: listCardPeriodGallon
          })
          return res.data.data
        }
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_PERIOD_GALLON_FAILURE
        })
      }
    }
  }

  export const getCardPeriodAmount = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_PERIOD_AMOUNT
        })
  
      
        const res = await axios.get(
          `${cardPeriodAmountUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )

        let listCardPeriodAmount = []
        res.data.data.map((periodAmount) => {
          if (periodAmount.name !== 'N/A') {
            listCardPeriodAmount.push(periodAmount)
          }
        })

        if (res.status === 200) {
          dispatch({
            type: CardTemplateActionTypes.GET_CARD_PERIOD_AMOUNT_SUCCESS,
            payload: listCardPeriodAmount
          })
          return res.data.data
        }
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_CARD_PERIOD_AMOUNT_FAILURE
        })
      }
    }
  }

  export const getVehicleTypes = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_VEHICLE_TYPE
        })
  
      
        const res = await axios.get(
          `${vehicleTypeUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )
        
        dispatch({
          type: CardTemplateActionTypes.GET_VEHICLE_TYPE_SUCCESS,
          payload: res.data.data
        })
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_VEHICLE_TYPE_FAILURE
        })
      }
    }
  }

  export const getTimeProfiles = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_TIME_PROFILE
        })
  
      
        const res = await axios.get(
          `${timeProfileUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )

        let listTimeProfile = []
        res.data.data.map((timeProfile) => {
          if (timeProfile.name !== 'N/A') {
            listTimeProfile.push(timeProfile)
          }
        })
        
        dispatch({
          type: CardTemplateActionTypes.GET_TIME_PROFILE_SUCCESS,
          payload: listTimeProfile
        })
  
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_TIME_PROFILE_FAILURE
        })
      }
    }
  }

  export const getFuelProfiles = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
  
      try {
        dispatch({
          type: CardTemplateActionTypes.GET_FUEL_PROFILE
        })
  
      
        const res = await axios.get(
          `${fuelProfileUrl}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )

        let listFuelProfile = []
        res.data.data.map((fuelProfile) => {
          if (fuelProfile.name !== 'N/A') {
            listFuelProfile.push(fuelProfile)
          }
        })
        
        dispatch({
          type: CardTemplateActionTypes.GET_FUEL_PROFILE_SUCCESS,
          payload: listFuelProfile
        })
  
      } catch (err) {
        
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: CardTemplateActionTypes.GET_FUEL_PROFILE_FAILURE
        })
      }
    }
  }

  export const bulkDeleteCardTemplate = (cardTemplates) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: CardTemplateActionTypes.BULK_DELETE_CARD_TEMPLATE
        })

      
        const res = await axios.post(
          `${bulkDeleteCardTemplateUrl}`,
          cardTemplates,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId:  account.account.id
            }
          }
        )
  
        batch(() => {
          dispatch({
            type: CardTemplateActionTypes.BULK_DELETE_CARD_TEMPLATE_SUCCESS,
            payload: res.data.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: "",
              status: CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS,
              error: false,
              withActionButton: false,
            }
          })
        })

      } catch (err) {
        
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: CardTemplateActionTypes.BULK_DELETE_CARD_TEMPLATE_FAILURE
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              email: "",
              status: CardTemplateActionTypes.DELETE_CARD_TEMPLATE_FAILURE,
              error: true,
              withActionButton: false,
            }
          })
        })
      }
    }
  }
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Modal, Table } from "react-bootstrap"
import Select from 'react-select';

import "./card-template.styles.scss";

const CardTemplateDetailModal = ({ show, handleClose, cardTemplateId }) => {

  const { 
    cardTemplates,
  } = useSelector((state) => state.cardTemplate);
  const [ selectedCardTemplate, setSelectedCardTemplate ] = useState({})
  const [ selectedCardTemplateId, setSelectedCardTemplateId] = useState(cardTemplateId)

  useEffect(() => {
    if (show) {
      if (cardTemplates && cardTemplates.data) {
        getCardTemplateValue(cardTemplateId)
      }
    }
  }, [show])

  const getCardTemplateValue = (templateId) => {
    let index = cardTemplates.data.findIndex(x => x.id === templateId)
    setSelectedCardTemplate({...cardTemplates.data[index]})
  }

  const getDropdownValue = () => {
    let val = ''
    let tempCardTemplates = [...cardTemplates.data]
    let indexCardTemplate = tempCardTemplates.findIndex(x => x.id === selectedCardTemplateId)
    if (indexCardTemplate !== -1) {
      val = {
        label: tempCardTemplates.length > 0 && selectedCardTemplateId !== '' ? tempCardTemplates[indexCardTemplate].template_name : '',
        value: selectedCardTemplateId
      }
    }
    return val
  }

  const changeCardTemplate = (id) => {
    setSelectedCardTemplateId(id)
    getCardTemplateValue(id)
  }

  const getRetailValue = () => {
    let retailValue = ''
    if (selectedCardTemplate.card_network_name) {
      if (selectedCardTemplate.card_network_name === 'CFN') {
        selectedCardTemplate.states.map((state, index) => {
          retailValue = retailValue + state + ((index + 1) < selectedCardTemplate.states.length ? ', ' : '')
        })
      } else {
        selectedCardTemplate.zips.map((zip, index) => {
          retailValue = retailValue + zip + ((index + 1) < selectedCardTemplate.zips.length ? ', ' : '')
        })
      }
    }
    return retailValue
  }

  return <Modal show={show} onHide={handleClose}
    className="modal-container-card card-template-detail-modal"
    centered
    >
      <Modal.Header closeButton className="header-form">
        <Modal.Title className="font-weight-bold">Card Template Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Card Template Name</Form.Label>
          <Select 
            components={{
              IndicatorSeparator: () => null
            }}
            options={cardTemplates.data.map(cardTemplate => {
              return {
                  label: cardTemplate.template_name,
                  value: cardTemplate.id
                  }
              })
            }
            value={getDropdownValue()}
            onChange={(e) => changeCardTemplate(e.value)}
          />
        </Form.Group>
        <Table className="card-template-detail-table">
          <tbody>
            <tr>
              <td>Template Name</td>
              <td>{selectedCardTemplate.template_name}</td>
            </tr>
            <tr>
              <td>Network</td>
              <td>{selectedCardTemplate.card_network_name}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>{selectedCardTemplate.card_type_driver_name}</td>
            </tr>
            <tr>
              <td>Card Type</td>
              <td>{selectedCardTemplate.card_type_name}</td>
            </tr>
            {
              (selectedCardTemplate.card_network_name === 'CNG' || selectedCardTemplate.card_network_name === 'Private Site') &&
              <tr>
                <td>Site Group</td>
                <td>{selectedCardTemplate.card_site_group_name}</td>
              </tr>
            }
            <tr>
              <td>Vehicle Type</td>
              <td>{selectedCardTemplate.card_vehicle_type_name}</td>
            </tr>
            {
              (['CFN', 'PPA', 'WEX'].includes(selectedCardTemplate.card_network_name) && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name)) &&
              <tr>
                <td>Fuel Profile</td>
                <td>{selectedCardTemplate.card_fuel_profile_name}</td>
              </tr>
            }
            {
              (['CFN', 'PPA'].includes(selectedCardTemplate.card_network_name) && ['Single', 'Driver'].includes(selectedCardTemplate.card_type_name)) &&
              <tr>
                <td>Time Profile</td>
                <td>{selectedCardTemplate.card_time_profile_name}</td>
              </tr>
            }
            {
              (['CFN', 'PPA'].includes(selectedCardTemplate.card_network_name) && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name)) &&
              <tr>
                <td>Max. Gallons per Fill</td>
                <td>{selectedCardTemplate.max_gallon_fill}</td>
              </tr>
            }
            {
              (['CFN', 'PPA'].includes(selectedCardTemplate.card_network_name) && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name)) &&
              <tr>
                <td>Max. Gallons per Period</td>
                <td>{selectedCardTemplate.max_gallon_period}</td>
              </tr>
            }
            {
              (['CFN', 'PPA'].includes(selectedCardTemplate.card_network_name) && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name)) &&
              <tr>
                <td>Period</td>
                <td>{selectedCardTemplate.card_period_gallon_name}</td>
              </tr>
            }
            {
              selectedCardTemplate.card_network_name === 'PPA' && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name) &&
              <tr>
                <td>Max. Dollar Amount per Fill</td>
                <td>{selectedCardTemplate.max_amount_fill}</td>
              </tr>
            }
            {
              selectedCardTemplate.card_network_name === 'PPA' && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name) &&
              <tr>
                <td>Max. Dollar Amount per Period</td>
                <td>{selectedCardTemplate.max_amount_period}</td>
              </tr>
            }
            {
              selectedCardTemplate.card_network_name === 'PPA' && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name) &&
              <tr>
                <td>Period</td>
                <td>{selectedCardTemplate.card_period_amount_name}</td>
              </tr>
            }
            {
              (['CFN', 'PPA'].includes(selectedCardTemplate.card_network_name) && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name)) &&
              <tr>
                <td>Strict Control</td>
                <td>{selectedCardTemplate.strict_control ? 'Available' : 'Unavailable'}</td>
              </tr>
            }
            {
              (['CFN', 'PPA'].includes(selectedCardTemplate.card_network_name) && ['Single', 'Vehicle'].includes(selectedCardTemplate.card_type_name)) &&
              <tr>
                <td>{selectedCardTemplate.card_type_name === 'CFN' ? 'Allow Retail Purchases Within the States' : 'Disallow Retail Purchase From The Zip Codes'}</td>
                <td>{getRetailValue()}</td>
              </tr>
            }
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
}

export default CardTemplateDetailModal